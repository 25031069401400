import {later} from '@mol-fe/mol-fe-async';

const CHECK_PERIOD = 5;

const checkDomReady = () => {
  /**
  * document.readyState === 'interactive' is an INVALID DOM ready test in IE10
  * https://github.com/mobify/mobifyjs/issues/136
  */
  if (document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') {
    later.go('DOM_READY');
  } else {
    // this is an implicit race as the first to complete will resolve the promise
    document.addEventListener('DOMContentLoaded', () => later.go('DOM_READY'));
    window.addEventListener('load', () => later.go('DOM_READY'));
  }
};

const checkBody = () => {
  if (document.body) {
    later.go('BODY_READY');
  } else {
    setTimeout(checkBody, CHECK_PERIOD);
  }
};

export {
  checkBody,
  checkDomReady
};
