import {getPageCriteria, getPageMetadata} from '@mol-fe/mol-fe-page-metadata';
import {getAutoRefresh} from '../../utils/getAutoRefresh';
import {event, pageView} from '../gtag';
import {getVisibilityState} from '../meta/visibility';

const handlePageRender = () => {
  const autoRefreshType = getAutoRefresh();
  const {contentType} = getPageMetadata();
  const {pageType} = getPageCriteria();

  const isArticle = pageType === 'article' || contentType === 'article';
  const articleViewIncrease = isArticle ? 1 : undefined;

  if (autoRefreshType) {
    event(`auto-refresh-${autoRefreshType}`, {
      autoRefreshIncrease: 1,
      // eslint-disable-next-line id-match
      event_category: 'auto refresh',
      visibilityState: getVisibilityState()
    }, `e_autorefresh${autoRefreshType}`);
  } else {
    pageView({
      articleViewIncrease
    });
  }
};

export const pageHandlers = {
  PAGE_RENDER_STARTED: handlePageRender
};
