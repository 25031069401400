/* eslint-disable complexity */
import {getPageCriteria, getPageMetadata} from '@mol-fe/mol-fe-page-metadata';
import {later} from '@mol-fe/mol-fe-async';
import {logger} from '@mol-fe/mol-fe-client-logger';
import {getRankedArticles} from './api';
import {clearPlaceHolder, replaceRelated} from './related';
import {insertRelatedPlaceholders, isNearPromotional} from './insert';

const replaceRelatedElement = async ({relatedElement, useSimilarArticles = false, instanceIndex = 0, topicGroup = null}) => {
  const articles = await getRankedArticles({
    topicGroup,
    useSimilarArticles
  });

  replaceRelated({
    articles,
    instanceIndex,
    relatedElement,
    topicGroup
  });
};

const AGE_THRESHOLD_SIMILAR = 365 * 24 * 60 * 60 * 1000;
const MODULE_COUNT = 2;
const EXCLUDED_TOPICS = ['Mail Best', 'Mail Best US'];

export const init = async () => {
  try {
    const {articleType, pageType, channel, sponsored} = getPageCriteria();
    const {domain, topics, publishedDate} = getPageMetadata();

    if (pageType !== 'article' || articleType === 'feature' || domain === 'thisismoney' || channel === 'money' || sponsored === 'true' || sponsored === true) {
      return;
    }

    if (topics && EXCLUDED_TOPICS.some((excludedTopic) => topics.includes(excludedTopic))) {
      return;
    }

    await later('DOM_READY');

    const oldRelatedModules = Array.from(document.querySelectorAll('.related-with-thumbs, .related-carousel'));
    const relatedElements = insertRelatedPlaceholders(MODULE_COUNT, oldRelatedModules);

    if (!relatedElements.length) {
      return;
    }

    const publishedTS = publishedDate && publishedDate.getTime() || 0;
    const isNewerArticle = Date.now() - publishedTS < AGE_THRESHOLD_SIMILAR;
    const topicGroup = topics && topics.includes('Royals') ? 'royals' : null;
    const useSimilarArticlesForFirst = !topicGroup && isNewerArticle && topics && topics.length;

    relatedElements.forEach((relatedElement, idx) => {
      if (idx > 0 && isNearPromotional(relatedElement)) {
        clearPlaceHolder(relatedElement);

        return;
      }

      const useSimilarArticles = useSimilarArticlesForFirst && idx === 0;

      const observer = new IntersectionObserver((entries, obs) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            replaceRelatedElement({
              instanceIndex: idx,
              relatedElement,
              topicGroup,
              useSimilarArticles
            });
            obs.unobserve(relatedElement);

            return;
          }
        }
      }, {rootMargin: '2000px'});

      observer.observe(relatedElement);
    });
  } catch (error) {
    logger.error('Error initialising mol-fe-related-replace', error);
  }
};
