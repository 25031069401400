import {getPageMetadata, getPageCriteria} from '@mol-fe/mol-fe-page-metadata';
import {addArticleRead, addArticleSeen} from './articles';
import {reorderArticles} from './reorder';

const trackArticlePreviewsSeen = () => {
  const articlePreviews = Array.from(document.querySelectorAll('.article-preview[data-id]'));
  const observer = new IntersectionObserver((entries, obs) => {
    for (const entry of entries) {
      if (entry.isIntersecting) {
        const element = entry.target;
        const articleId = Number(element.dataset.id);

        addArticleSeen(articleId);
        obs.unobserve(element);
      }
    }
  }, {threshold: 0.5});

  for (const articlePreview of articlePreviews) {
    observer.observe(articlePreview);
  }
};

const REORDER_CHANNELS = ['home', 'ushome', 'auhome'];

export const init = () => {
  const {renderPlatform} = getPageMetadata();
  const {channel, pageType} = getPageCriteria();

  if (renderPlatform !== 'mobile') {
    return;
  }

  if (location.search && location.search.includes('debugDisableMobileReorder=true')) {
    return;
  }

  if (REORDER_CHANNELS.includes(channel) && pageType === 'channel') {
    reorderArticles();
  }

  if (pageType === 'channel') {
    trackArticlePreviewsSeen();
  }

  if (pageType === 'article') {
    const {articleId} = getPageCriteria();

    addArticleRead(Number(articleId));
  }
};
