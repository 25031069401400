/* eslint-disable complexity */
import {logger} from '@mol-fe/mol-fe-client-logger';

const deviceData = {};

const parseMobileVersion = (userAgent, re) => {
  let mobileVersion = (userAgent.match(re) || [])[1] || '';

  mobileVersion = mobileVersion.split(/[._]/g);

  return {
    build: parseInt(mobileVersion[2] || -1, 10),
    major: parseInt(mobileVersion[0] || -1, 10),
    minor: parseInt(mobileVersion[1] || -1, 10)
  };
};

const parseUserAgent = () => {
  const ua = navigator.userAgent;

  deviceData.ua = ua;
  deviceData.isWindows = Boolean(ua.match(/Windows Phone|iemobile|WPDesktop/i));

  // MOL-20256: proxy test for an iPad, as UA string does not report iPad
  // See: https://forums.developer.apple.com/thread/119186
  try {
    deviceData.isIPad = (Boolean(ua.match(/iPad/i)) || navigator.platform === 'iPad' || navigator.platform === 'MacIntel' && 'ontouchend' in document) && !deviceData.isWindows;
  } catch (error) {
    deviceData.isIPad = false;
  }

  deviceData.isIDevice = (Boolean(ua.match(/(iPad|iPhone|iPod)/i)) || deviceData.isIPad) && !deviceData.isWindows;
  deviceData.isIPhone = Boolean(ua.match(/iPhone/i)) && !deviceData.isWindows;
  deviceData.isAndroid = Boolean(ua.match(/Android/i)) && !deviceData.isWindows;
  deviceData.isChrome = Boolean(ua.match(/Chrome/i));
  deviceData.isFirefox = Boolean(ua.match(/Firefox/i));
  deviceData.isKindleSilk = Boolean(ua.match(/Silk|Kindle/i));
  deviceData.isAndroidPhone = deviceData.isAndroid && Boolean(ua.match(/Mobile/i));
  deviceData.isAndroidTablet = deviceData.isAndroid && !Boolean(ua.match(/Mobile/i));
  deviceData.isMobile = deviceData.isIDevice || deviceData.isAndroid || deviceData.isWindows;
  deviceData.isAndroidStock = deviceData.isAndroid && !deviceData.isChrome && !deviceData.isFirefox && !deviceData.isKindleSilk;

  try {
    if (deviceData.isIDevice) {
      deviceData.mobileName = ua.match(/(iPad|iPhone|iPod)/i) ? ua.match(/(iPad|iPhone|iPod)/i)[0] : 'iPad';
      deviceData.mobileVersion = parseMobileVersion(ua, /OS ((?:\d+[._]?)+)/i);
    } else if (deviceData.isAndroid) {
      deviceData.mobileName = 'Android';
      deviceData.mobileVersion = parseMobileVersion(ua, /Android ((?:\d+[._]?)+)/i);
    } else if (deviceData.isWindows) {
      deviceData.mobileName = 'Windows Phone';
      deviceData.mobileVersion = parseMobileVersion(ua, /Windows Phone ((?:\d+[._]?)+)/i);
    } else {
      deviceData.mobileName = null;
      deviceData.mobileVersion = {
        build: -1,
        major: -1,
        minor: -1
      };
    }
  } catch (error) {
    logger.error('Failed to parse mobile name and version', error);
    deviceData.mobileName = 'unknown';
  }
};
const isWindows = () => deviceData.isWindows;
const isIDevice = () => deviceData.isIDevice;
const isIPhone = () => deviceData.isIPhone;
const isIPad = () => deviceData.isIPad;
const isAndroid = () => deviceData.isAndroid;
const isChrome = () => deviceData.isChrome;
const isFirefox = () => deviceData.isFirefox;
const isKindleSilk = () => deviceData.isKindleSilk;
const isAndroidPhone = () => deviceData.isAndroidPhone;
const isAndroidTablet = () => deviceData.isAndroidTablet;
const isMobile = () => deviceData.isMobile;
const isAndroidStock = () => deviceData.isAndroidStock;
const getMobileVersion = () => deviceData.mobileVersion;

const getZoom = () => {
  logger.warn('"getZoom" function has been deprecated as it does not provide reliable values');
  if (screen.deviceXDPI && screen.logicalXDPI) {
    return screen.deviceXDPI / screen.logicalXDPI;
  } else if (document.width) {
    return document.width / window.innerWidth;
  } else if (screen.width && window.innerWidth) {
    return screen.width / window.innerWidth;
  } else {
    const maxWidth = Math.max(document.documentElement.clientWidth, document.body.offsetWidth, document.body.scrollWidth);

    return maxWidth / (window.innerWidth || document.documentElement.clientWidth);
  }
};
const isZoomed = () => getZoom() > 1;

const isLandscape = () => {
  if (window.matchMedia) {
    return window.matchMedia('(orientation:landscape)').matches;
  } else {
    return (window.innerWidth || document.documentElement.clientWidth) > (window.innerHeight || document.documentElement.clientHeight);
  }
};
const isPortrait = () => !isLandscape();

const enableScroll = () => {
  document.body.style.overflow = 'auto';
  document.ontouchmove = () => true;
};
const disableScroll = () => {
  document.body.style.overflow = 'hidden';
  document.ontouchmove = (event) => event.preventDefault();
};

const setupMobileClass = () => {
  logger.warn('"setupMobileClass" has been deprecated and "features detection" should be prefered over "device detection"');
  if (deviceData.mobileName) {
    const mobileClass = deviceData.mobileName.toLowerCase().replace(/ /, '-');

    document.body.classList.add(mobileClass);
  }
};

// eslint-disable-next-line promise/prefer-await-to-callbacks
const onTouchEnd = (callback) => {
  // Runs a method on touch end including single tap and double tap
  // Please note - the method will run twice
  setTimeout(callback, 400);

  // eslint-disable-next-line promise/prefer-await-to-callbacks
  return callback();
};

const fixAndroidStockOrientation = () => {
  // fix for android browser http://moduscreate.com/orientation-change-zoom-scale-android-bug/
  logger.debug('Executing Android Stock browser Orientation bug fix');

  window.addEventListener('orientationchange', () => {
    const metaViewPort = document.querySelector('meta[name=viewport]');

    if (metaViewPort) {
      const content = metaViewPort.getAttribute('content');

      metaViewPort.setAttribute('content', 'width=10000, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=0');
      setTimeout(() => {
        metaViewPort.setAttribute('content', content);
      }, 0);
    }
  });
};

try {
  parseUserAgent();
  if (deviceData.isAndroidStock) {
    fixAndroidStockOrientation();
  }
} catch (error) {
  logger.error('Error parsing user agent', error);
}

const mobileUtils = {
  deviceData,
  disableScroll,
  enableScroll,
  getMobileVersion,
  getZoom,
  isAndroid,
  isAndroidPhone,
  isAndroidStock,
  isAndroidTablet,
  isChrome,
  isFirefox,
  isIDevice,
  isIPad,
  isIPhone,
  isKindleSilk,
  isLandscape,
  isMobile,
  isPortrait,
  isWindows,
  isZoomed,
  onTouchEnd,
  parseUserAgent,
  setupMobileClass
};

export {
  deviceData,
  disableScroll,
  enableScroll,
  getMobileVersion,
  getZoom,
  isAndroid,
  isAndroidPhone,
  isAndroidStock,
  isAndroidTablet,
  isChrome,
  isFirefox,
  isIDevice,
  isIPad,
  isIPhone,
  isKindleSilk,
  isLandscape,
  isMobile,
  isPortrait,
  isWindows,
  isZoomed,
  onTouchEnd,
  parseUserAgent,
  setupMobileClass
};

export default mobileUtils;
