import {PACKAGE_NAME} from '../../../../../config';
import publishTrackingEvent from '../../../publishTrackingEvent/publishTrackingEvent';
import {trackPermutive} from '../../../publishTrackingEvent/trackPermutive';

export const CLICK_EVENT_NAME = 'click';
export const FILENAME = 'onXpModuleNodeClick';

const onXpModuleNodeClick = (xpModuleName, campaignId, xpModuleNode, event) => {
  if (typeof xpModuleName !== 'string') {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.invalidProps`,
      {
        xpModuleName,
      }
    );

    return null;
  }

  try {
    publishTrackingEvent({
      campaignId,
      eventName: CLICK_EVENT_NAME,
      xpModuleName,
    });

    trackPermutive({
      campaignId,
      event,
      xpModuleName,
      xpModuleNode
    });

    return true;
  } catch (error) {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.thrownError`,
      {
        error,
        xpModuleName
      }
    );

    return null;
  }
};

export default onXpModuleNodeClick;
