/* eslint-disable promise/prefer-await-to-then */
const waitFor = (check, interval) => {
  if (check()) {
    return Promise.resolve();
  }

  return new Promise((resolve) => {
    setTimeout(() =>
      waitFor(check, interval)
        .then(resolve)
      , interval);
  });
};

export default waitFor;
