const matches = () => {
  if (typeof Element.prototype.matches !== 'function') {
    Element.prototype.matches = Element.prototype.matchesSelector ||
      Element.prototype.mozMatchesSelector ||
      Element.prototype.msMatchesSelector ||
      Element.prototype.oMatchesSelector ||
      Element.prototype.webkitMatchesSelector ||
      function (selector) {
        return Array.from((this.document || this.ownerDocument).querySelectorAll(selector))
          .includes(this);
      };
  }
};

export default matches;
