/* eslint-disable complexity */
import {event} from '../gtag';

const createHandler = (category, action, nonInteraction = false) => (_, data) => {
  const params = {
    // eslint-disable-next-line id-match
    event_category: category,
    // eslint-disable-next-line id-match
    event_label: data && data.tab || data && data.label || undefined,
    // eslint-disable-next-line id-match
    non_interaction: nonInteraction,
    selectedValue: data && data.tab || data && data.label || undefined
  };

  if (data && typeof data.value !== 'undefined') {
    params.value = data.value;

    if (action === 'load time') {
      params.loadTime = data.value;
    }
  }

  event(action, params);
};

export const pageCommentHandlers = {
  COMMENTS_ADD_COMMENT_CLICK: createHandler('comment', 'add comment click'),
  COMMENTS_CLEAR: createHandler('comment', 'clear'),
  COMMENTS_DOWNVOTE: createHandler('comment', 'downvote'),
  COMMENTS_IMPRESSION: createHandler('comment', 'impression', true),
  COMMENTS_LOAD_TIME: createHandler('comment', 'load time', true),
  COMMENTS_LOGIN_REDIRECT: createHandler('comment', 'login redirect'),
  COMMENTS_NEW_COMMENT_CLICK: createHandler('comment', 'new comment click'),
  COMMENTS_OVERLAY_CLOSE: createHandler('comment', 'overlay close'),
  COMMENTS_OVERLAY_OPEN: createHandler('comment', 'overlay open'),
  COMMENTS_PAGINATION_CLICK: createHandler('comment', 'pagination click'),
  COMMENTS_PROFILE_CLICK: createHandler('comment', 'profile click'),
  COMMENTS_REPLY_CLICK: createHandler('comment', 'reply click'),
  COMMENTS_REPLY_SUBMIT: createHandler('comment', 'reply submit'),
  COMMENTS_REPORT_CLICK: createHandler('comment', 'report abuse'),
  COMMENTS_SHOW_MORE_CLICK: createHandler('comment', 'show more'),
  COMMENTS_SUBMIT_COMMENT: createHandler('comment', 'submit'),
  COMMENTS_SUBMIT_SUCCESS: createHandler('comment', 'submit success'),
  COMMENTS_UPVOTE: createHandler('comment', 'upvote'),
  COMMENTS_VIEW_ALL: createHandler('comment', 'view all'),
  PAGE_COMMENT_TAB_SWAPPED: createHandler('comment tab', 'view'),
  SOCIAL_SHARE_COMMENTS: createHandler('comment', 'added')
};
