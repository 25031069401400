/* eslint-disable filenames/match-exported */
import makeRequest from './src/makeRequest';
import * as url from './src/url';
import * as googleAnalytics from './src/googleAnalytics';

const molFeClientUtils = {
  googleAnalytics,
  makeRequest,
  url
};

export {
  googleAnalytics,
  makeRequest,
  url
};

export default molFeClientUtils;
