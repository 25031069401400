import {event} from '../gtag';

const createHandler = (category, action) => () => {
  event(action, {
    // eslint-disable-next-line id-match
    event_category: category,
    // eslint-disable-next-line id-match
    non_interaction: false
  });
};

export const billboardHandlers = {
  AD_BILLBOARD_CLOSED: createHandler('billboard ad', 'clicked to close')
};
