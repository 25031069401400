const STORAGE_PREFIX = 'mol-fe-newzit-notification-promo-';

const getFromStorage = (key) => {
  try {
    if ('localStorage' in window) {
      const rawValue = window.localStorage.getItem(`${STORAGE_PREFIX}${key}`);

      if (rawValue) {
        return JSON.parse(rawValue);
      }

      return null;
    }
  } catch (error) {
    // Ignore failures
  }

  return null;
};

const KEY_STATUS = 'newzitStatus';
const KEY_LAST_SHOWN = 'lastShown';

export const hasActionedPrompt = () => {
  const newzitStatus = getFromStorage(KEY_STATUS);

  return newzitStatus && newzitStatus !== 'none';
};

export const seenWithin = (period) => {
  const lastSeen = getFromStorage(KEY_LAST_SHOWN);

  return lastSeen && Date.now() - lastSeen < period;
};
