import {event} from '../gtag';

const createHandler = (category, action, nonInteraction) => (_, data) => {
  const {urlClicked, articlePosition} = data;

  event(action, {
    articlePosition,
    // eslint-disable-next-line id-match
    event_category: category,
    // eslint-disable-next-line id-match
    non_interaction: Boolean(nonInteraction),
    urlClicked
  });
};

export const articleRecommendationHandlers = {
  ARTICLE_RECOMMENDATION_XP_CLICK: createHandler('article recommendation module', 'click'),
  ARTICLE_RECOMMENDATION_XP_IMPRESSION: createHandler('article recommendation module', 'impression', true),
  ARTICLE_RECOMMENDATION_XP_VIEWABLE: createHandler('article recommendation module', 'view', true)
};
