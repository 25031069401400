/* eslint-disable filenames/match-regex, import/max-dependencies */
// eslint-disable-next-line import/no-unassigned-import
import '@mol-fe/mol-fe-third-party';
import * as molFeAsync from '@mol-fe/mol-fe-async';
import * as molFeClientLogger from '@mol-fe/mol-fe-client-logger';
import * as molFeComponents from '@mol-fe/mol-fe-components';
import * as molFeCookieReinforcer from '@mol-fe/mol-fe-cookie-reinforcer';
import * as molFeDomHelpers from '@mol-fe/mol-fe-dom-helpers';
import * as molFeLazyLoadImages from '@mol-fe/mol-fe-lazy-load-images';
import * as molFeMiniLodash from '@mol-fe/mol-fe-mini-lodash';
import * as molFeMobileutils from '@mol-fe/mol-fe-mobileutils';
import * as molFeMultivariant from '@mol-fe/mol-fe-multivariant';
import * as molFePageMetadata from '@mol-fe/mol-fe-page-metadata';
import * as molFeReorderMobileChannel from '@mol-fe/mol-fe-reorder-mobile-channel';
import * as molFeSegmentation from '@mol-fe/mol-fe-segmentation';
import * as molFeSmartBanner from '@mol-fe/mol-fe-smart-banner';
import * as molFeTracking from '@mol-fe/mol-fe-tracking';
import * as molFeRemixd from '@mol-fe/mol-fe-remixd';
import * as molFeWebVitals from '@mol-fe/mol-fe-web-vitals';
import * as molFeArticleRead from '@mol-fe/mol-fe-article-read';
import * as molFeArticleTruncation from '@mol-fe/mol-fe-article-truncation';
// eslint-disable-next-line import/no-unassigned-import
import '@mol-fe/mol-fe-browser-notifications-sync';

export {
  molFeArticleRead,
  molFeAsync,
  molFeClientLogger,
  molFeComponents,
  molFeCookieReinforcer,
  molFeDomHelpers,
  molFeLazyLoadImages,
  molFeMiniLodash,
  molFeMobileutils,
  molFeMultivariant,
  molFePageMetadata,
  molFeRemixd,
  molFeReorderMobileChannel,
  molFeSegmentation,
  molFeSmartBanner,
  molFeTracking,
  molFeWebVitals,
  molFeArticleTruncation
};

export * from './DMLegacy';
