// https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/before
import createDocumentFragment from '../utils/createDocumentFragment';

const before = () => {
  const prototypes = [Element.prototype, CharacterData.prototype, DocumentType.prototype];

  prototypes.forEach((prototype) => {
    if (typeof prototype.before !== 'function') {
      prototype.before = function (...elements) {
        const documentFragment = createDocumentFragment(elements);

        this.parentNode.insertBefore(documentFragment, this);
      };
    }
  });
};

export default before;
