import {getPageMetadata} from '@mol-fe/mol-fe-page-metadata';
import {PACKAGE_NAME, SUPPORTED_EVENTS} from '../../../../../config';

export const FILENAME = 'postBidmaxTrackingMessage';

const postBidmaxTrackingMessage = ({eventName, xpModuleName, campaignId}) => {
  if (!SUPPORTED_EVENTS.includes(eventName)) {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.invalidProps`,
      {
        eventName,
      }
    );

    return null;
  }

  if (typeof xpModuleName !== 'string') {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.invalidProps`,
      {
        xpModuleName,
      }
    );

    return null;
  }

  try {
    const {environment} = getPageMetadata();

    const params = {
      environment,
      event: eventName,
      module: 'mol-fe-promotional-slot',
      moduleVariant: xpModuleName,
      url: location.href
    };

    if (campaignId) {
      params.campaignId = campaignId;
    }

    window.postMessage(`__bidmax\n${JSON.stringify(params)}`, '*');
  } catch (error) {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.thrownError`,
      {
        error,
        eventName,
        xpModuleName,
      }
    );

    return null;
  }

  return true;
};

export default postBidmaxTrackingMessage;
