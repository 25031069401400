import {event} from '../gtag';

const createHandler = (category, action, nonInteraction) => (_, data) => {
  const {slotType} = data;

  event(action, {
    // eslint-disable-next-line id-match
    event_category: category,
    // eslint-disable-next-line id-match
    event_label: slotType,
    // eslint-disable-next-line id-match
    non_interaction: Boolean(nonInteraction)
  });
};

export const adSlotHandlers = {
  AD_SLOT_LOADED_ON_PAGE: createHandler('ad slot', 'loaded on page', true)
};
