/* eslint-disable prefer-promise-reject-errors */
const getRequest = function (url) {
  return new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();
    let calledErrorCallback = false;

    request.open('GET', url);

    request.onreadystatechange = () => {
      if (request.readyState > 3) {
        if (request.status >= 200 && request.status < 300) {
          resolve(request);
        } else if (!calledErrorCallback) {
          calledErrorCallback = true;
          reject(`Error in server response, status: ${request.status}`);
        }
      }
    };

    request.onerror = () => {
      if (!calledErrorCallback) {
        calledErrorCallback = true;
        reject(`Error making request, status: ${request.status}`);
      }
    };

    request.send();
  });
};

export default getRequest;
