import ted from '../ted';

const handleWebPushEvent = function (_eventName, data) {
  ted.tedEvent('push', data);
};

const handleWebPullEvent = function (_eventName, data) {
  ted.tedEvent('pull', data);
};

const webPush = {
  WEB_PULL_NOTIFICATION_CLICK: handleWebPullEvent,
  WEB_PUSH_NOTIFICATION_CLICK: handleWebPushEvent,
  WEB_PUSH_PROMPT_CLICK: handleWebPushEvent,
  WEB_PUSH_PROMPT_IMPRESSION: handleWebPushEvent
};

export default webPush;
