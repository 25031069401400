export const isAdFreeEntitled = () => {
  try {
    const afstatus = JSON.parse(window.localStorage.getItem('mol-fe-paywall-status'));
    const hasLoggedIn = window.DM && 'isLoggedIn' in window.DM;

    if (window.PageCriteria && window.PageCriteria.geo === 'US') {
      return false;
    }

    return !afstatus.invalidated && !afstatus.deviceDisabled && afstatus.validUntil > Date.now() && (!hasLoggedIn || window.DM.isLoggedIn);
  } catch (error) {
    // returns false
  }

  return false;
};
