import {event} from '../gtag';

const createHandler = (category) => (_, data) => {
  const actionName = data.name
    .replace('fidelity-campaign-', '')
    .replaceAll('_', ' ')
    .replaceAll('-', ' ');

  event(actionName, {
    // eslint-disable-next-line id-match
    event_category: category,
    // eslint-disable-next-line id-match
    event_label: data.value,
    // eslint-disable-next-line id-match
    non_interaction: false,
    typeoffidelitycampaign: data.value
  }, 'e_fidelitycampaignclick');
};

export const fidelityHandlers = {
  FIDELITY_CAMPAIGN_EVENT: createHandler('fidelity campaign')
};
