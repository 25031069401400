import axios from 'axios';
import {hostname, PACKAGE_NAME, TIMEOUT_IN_MILLISECONDS} from '../../../../config';

export const FILENAME = 'retrieveXpModuleHeadJson';

const retrieveXpModuleHeadJson = async ({
  name,
  env,
  values
}) => {
  let url;

  try {
    url = `https://${hostname}/xpmodules/${name}/renderHeadJson?data=${encodeURIComponent(JSON.stringify({env,
      values}))}`;
  } catch (error) {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.urlError`,
      {
        env,
        error,
        hostname,
        name,
        values
      }
    );

    return null;
  }

  try {
    const response = await axios.get(url, {
      timeout: TIMEOUT_IN_MILLISECONDS
    });

    const headJson = response.data;

    if (!headJson) {
      return {
        scripts: [],
        styles: [],
      };
    }

    if (!Array.isArray(headJson.scripts)) {
      headJson.scripts = [];
    }

    if (!Array.isArray(headJson.styles)) {
      headJson.styles = [];
    }

    return headJson;
  } catch (error) {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.thrownError`,
      {
        error,
        url
      }
    );

    return null;
  }
};

export default retrieveXpModuleHeadJson;
