import {PACKAGE_NAME} from '../../../../config';

export const FILENAME = 'getElementFromSelector';

const getElementFromSelector = (elementSelector) => {
  try {
    const element = document.querySelector(elementSelector);

    if (!element) {
      console.error(
        `[${PACKAGE_NAME}] ${FILENAME}.elementNotFound`, {
          element,
          elementSelector,
        }
      );
    }

    return element;
  } catch (error) {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.thrownError`, {
        elementSelector,
        error,
      }
    );

    return null;
  }
};

export default getElementFromSelector;
