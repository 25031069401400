import {getPageMetadata} from '@mol-fe/mol-fe-page-metadata';
import {event} from '../gtag';

// eslint-disable-next-line complexity
const handleSocialShareEvent = function (eventName, data) {
  const metaFromPage = getPageMetadata();
  const dataMeta = data.pageMetadata || {};
  const eventLabel = data.site + '_share_article-' + data.placement;
  const eventCategory = 'social share';
  const eventAction = 'click';
  const articleTitle = dataMeta.articleTitle || data.articleTitle;
  const articleId = dataMeta.articleId || data.articleId;
  const channel = dataMeta.channel || metaFromPage.channel;
  const subChannel = dataMeta.channel || metaFromPage.subChannel;
  let videoPlayerVersion;
  let galleryImageAction;
  let galleryImageId;
  let videoTitle;
  let videoInternalId;

  if (data.version && data.placement === 'videoplayer') {
    videoPlayerVersion = 'videoplayer_' + data.version;
  }

  if (data.id && data.nthPosition) {
    galleryImageAction = 'image ' + (data.nthPosition - 1) + ' image share';
    galleryImageId = data.id;
  }

  if (data.videoTitle) {
    videoTitle = data.videoTitle;
  }
  if (data.videoId) {
    videoInternalId = data.videoId;
  }

  if (data.placement === 'video-lead' && !videoTitle && !videoInternalId) {
    try {
      const videoHeadlineEl = document.querySelector('.video-lead-info .video-headline');
      const videoLinkEl = videoHeadlineEl && videoHeadlineEl.querySelector('a');
      const videoUrl = videoLinkEl ? videoLinkEl.getAttribute('href') : window.location.href;

      videoTitle = videoHeadlineEl.innerText.trim().replace('Video: ', '');
      videoInternalId = videoUrl && videoUrl.match(/video-(\d+)/)[1];
    } catch (error) {
      // Fail silently
    }
  }

  event(eventAction, {
    articleId,
    articleTitle,
    channel,
    // eslint-disable-next-line id-match
    event_category: eventCategory,
    // eslint-disable-next-line id-match
    event_label: eventLabel,
    galleryImageAction,
    galleryImageId,
    // eslint-disable-next-line id-match
    non_interaction: false,
    sharePlacement: eventLabel,
    socialShareClicksIncrease: 1,
    subChannel,
    videoInternalId,
    videoPlayerVersion,
    videoTitle
  });
};

export const socialHandlers = {
  SOCIAL_SHARE_CLICKED: handleSocialShareEvent
};
