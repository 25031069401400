/* eslint-disable complexity */
import {getPageCriteria, getPageMetadata} from '@mol-fe/mol-fe-page-metadata';
import {logger} from '@mol-fe/mol-fe-client-logger';
import {pageEvents} from '@mol-fe/mol-fe-tracking';
import {truncateArticle, expandArticle} from './truncation';
import {DISABLED_GEOS, MIN_TRIGGER_HEIGHT_DESKTOP, MIN_TRIGGER_HEIGHT_MOBILE, TRUNCATE_INTERNAL_MIN_WAIT} from './constants';
import {getArticleBody} from './article';
import {getFromStorage} from './storage';

export const init = () => {
  try {
    const {geo, articleType, pageType, sponsored, channel} = getPageCriteria();
    const {renderPlatform} = getPageMetadata();
    const skipGeoCheck = location.search.match(/debugSkipArticleTruncationGeoCheck=true/);

    if (DISABLED_GEOS.includes(geo) && !skipGeoCheck) {
      return;
    }

    if (pageType !== 'article') {
      return;
    }

    if (channel === 'home') {
      pageEvents.publish(pageEvents.ARTICLE_TRUNCATION_INELIGIBLE, {reason: 'homechannel'});

      return;
    }

    if (articleType === 'feature') {
      pageEvents.publish(pageEvents.ARTICLE_TRUNCATION_INELIGIBLE, {reason: 'feature'});

      return;
    }

    if (sponsored === 'true' || sponsored === true) {
      pageEvents.publish(pageEvents.ARTICLE_TRUNCATION_INELIGIBLE, {reason: 'sponsored'});

      return;
    }

    const {referrer} = document;
    const isInternalReferrer = referrer && referrer.startsWith(location.origin);

    if (isInternalReferrer) {
      const lastTruncated = getFromStorage('last');
      const now = Date.now();

      if (lastTruncated && now - lastTruncated < TRUNCATE_INTERNAL_MIN_WAIT) {
        pageEvents.publish(pageEvents.ARTICLE_TRUNCATION_INELIGIBLE, {reason: 'internal'});

        return;
      }
    }

    const hash = location.hash;

    if (hash && hash.match(/^#.+/)) {
      if (renderPlatform !== 'mobile' || !hash.match(/^#(comment|article-\d+)/)) {
        pageEvents.publish(pageEvents.ARTICLE_TRUNCATION_INELIGIBLE, {reason: 'hash'});

        return;
      }
    }

    const articleBody = getArticleBody();

    if (!articleBody) {
      pageEvents.publish(pageEvents.ARTICLE_TRUNCATION_INELIGIBLE, {reason: 'bodymissing'});

      return;
    }

    const triggerHeight = renderPlatform === 'mobile' ? MIN_TRIGGER_HEIGHT_MOBILE : MIN_TRIGGER_HEIGHT_DESKTOP;

    if (renderPlatform === 'mobile') {
      window.addEventListener('orientationchange', () => {
        requestAnimationFrame(() => {
          if (articleBody.scrollHeight < triggerHeight) {
            expandArticle({nonInteraction: true});
          } else {
            truncateArticle({nonInteraction: true});
          }
        });
      });
    }

    if (articleBody.offsetHeight < triggerHeight) {
      pageEvents.publish(pageEvents.ARTICLE_TRUNCATION_INELIGIBLE, {reason: 'height'});

      return;
    }

    truncateArticle();
  } catch (error) {
    logger.error('Error initialising mol-fe-article-truncation', error);
  }
};
