import {later} from '@mol-fe/mol-fe-async';
import {logger} from '@mol-fe/mol-fe-client-logger';
import {init} from './init';
// eslint-disable-next-line import/no-unresolved
import './styles.scss';

try {
  later('PAGE_METADATA_SET', init);
} catch (error) {
  logger.error('Error initialising mol-fe-related-replace', error);
}
