import {PACKAGE_NAME} from '../../../config';
import onXpModuleNodeClick from './utils/onXpModuleNodeClick/onXpModuleNodeClick';

export const FILENAME = 'addOnClickEventListenerToXpModuleNode';

const addOnClickEventListenerToXpModuleNode = (xpModuleNode, xpModuleName, campaignId) => {
  if (!(xpModuleNode instanceof HTMLDivElement)) {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.invalidProps`,
      {
        xpModuleNode,
      }
    );

    return null;
  }

  if (typeof xpModuleName !== 'string') {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.invalidProps`,
      {
        xpModuleName,
      }
    );

    return null;
  }

  xpModuleNode.addEventListener('click', (event) => {
    onXpModuleNodeClick(xpModuleName, campaignId, xpModuleNode, event);
  });

  return xpModuleNode;
};

export default addOnClickEventListenerToXpModuleNode;
