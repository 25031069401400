import {getPageCriteria, getPageMetadata} from '@mol-fe/mol-fe-page-metadata';
import {PACKAGE_NAME} from '../../../../../config';

export const FILENAME = 'getXpModuleEnvConfig';

const getXpModuleEnvConfig = () => {
  try {
    const {channel, geo, subchannel} = getPageCriteria();
    const {renderPlatform} = getPageMetadata();

    return {
      channel: subchannel || channel,
      geo: geo.toLowerCase(),
      platform: renderPlatform === 'mobile' ? 'mol.web.mobile' : 'mol.web.desktop',
      topParentChannel: channel,
      url: `${window.location.pathname}${window.location.search}`
    };
  } catch (error) {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.thrownError`,
      {
        error,
      }
    );

    return null;
  }
};

export default getXpModuleEnvConfig;
