import ted from '../ted';

const eventCounts = {};

const handleGenericEvent = (eventName, data) => {
  if (!eventCounts[eventName]) {
    eventCounts[eventName] = 0;
  }

  eventCounts[eventName]++;

  ted.tedEvent('page_event', {
    action: 'set',
    name: `openweb_${eventName}`,
    value: data && typeof data.value !== 'undefined' ? data.value : eventCounts[eventName]
  });
};

const createHandler = (action) => (_, data) => handleGenericEvent(action, data);

const openweb = {
  OPENWEB_DELETE_MESSAGE: createHandler('delete_message'),
  OPENWEB_DOWNVOTE: createHandler('downvote'),
  OPENWEB_EDIT_MESSAGE: createHandler('edit_message'),
  OPENWEB_IMPRESSION: createHandler('impression'),
  OPENWEB_LOAD_ERROR: createHandler('load_error'),
  OPENWEB_LOAD_TIME: createHandler('load_time'),
  OPENWEB_LOGIN_START: createHandler('login_start'),
  OPENWEB_OPEN_PROFILE: createHandler('open_profile'),
  OPENWEB_POST_LOGIN_SUCCESS: createHandler('post_login_success'),
  OPENWEB_SEND_FAIL: createHandler('send_fail'),
  OPENWEB_SEND_SUCCESS: createHandler('send_success'),
  OPENWEB_SHARE_CONTEXT: createHandler('share_context'),
  OPENWEB_SHARE_TYPE: createHandler('share_type'),
  OPENWEB_SORT_OPEN: createHandler('sort_open'),
  OPENWEB_SORT_SELECT: createHandler('sort_select'),
  OPENWEB_UPVOTE: createHandler('upvote'),
  OPENWEB_VIEWABLE: createHandler('viewable')
};

export default openweb;
