// https://developers.google.com/analytics/devguides/collection/gtagjs/events
import {event} from '../gtag';

const INTERACTIVE_EVENTS = ['click', 'viewable'];
const IGNORE_GA_EVENTS = ['impression', 'viewable'];

const promotionalSlotEventHandler = (_, data) => {
  const {eventName, xpModuleName, campaignId} = data;

  if (IGNORE_GA_EVENTS.includes(eventName)) {
    return;
  }

  const label = `${xpModuleName}${campaignId ? `|${campaignId}` : ''}`;

  event(eventName, {
    // eslint-disable-next-line id-match
    event_category: 'promotional slot',
    // eslint-disable-next-line id-match
    event_label: label,
    // eslint-disable-next-line id-match
    non_interaction: Boolean(!INTERACTIVE_EVENTS.includes(eventName)),
    typeofpromotionalslot: label
  });
};

export const promotionalSlotHandlers = {
  PROMOTIONAL_SLOT_EVENT: promotionalSlotEventHandler
};
