/* eslint-disable complexity */
import {getPageCriteria, getPageMetadata} from '@mol-fe/mol-fe-page-metadata';
import {
  hasActionedPrompt as hasActionedNewzitPrompt,
  seenWithin as seenNewzitPromptWithin
} from './newzit';

const STORAGE_PREFIX = 'mol-fe-windows-notification-widget-';

const getFromStorage = (key) => {
  try {
    if ('localStorage' in window) {
      const rawValue = window.localStorage.getItem(`${STORAGE_PREFIX}${key}`);

      if (rawValue) {
        return JSON.parse(rawValue);
      }

      return null;
    }
  } catch (error) {
    // Ignore failures
  }

  return null;
};

const KEY_STATUS = 'status';
const KEY_LAST_SEEN = 'lastSeen';
const STATUS_DEFAULT = 'default';
const STATUS_BLOCKED = 'blocked';
const STATUS_ALLOWED = 'allowed';

const ONE_DAY_MS = 24 * 3600 * 1000;
const ONE_WEEK_DAYS = 7;
const IGNORED_WIDGET_WAIT_PERIOD = ONE_DAY_MS * ONE_WEEK_DAYS;
const BLOCKED_WIDGET_WAIT_PERIOD = ONE_DAY_MS * ONE_WEEK_DAYS * 2;

const WINDOWS_REGEX = /Windows NT (?<version>\d\d?)./;
const WINDOWS_VERSION_CUTOFF = 10;

const isTrueOrTrueString = (value) => value === true || value === 'true';

const inWaitPeriod = (lastSeen, waitPeriodMs) => lastSeen && Date.now() - lastSeen < waitPeriodMs;

const getWindowsVersion = (userAgent) => {
  const execed = WINDOWS_REGEX.exec(userAgent);

  if (execed && execed.groups && execed.groups.version && !isNaN(execed.groups.version)) {
    return Number(execed.groups.version);
  }

  return null;
};

const getShowCriteria = () => {
  // Need to show it in this order in tracking
  /* eslint-disable sort-keys */
  const showCriteria = {
    wv: true,
    co: true,
    dk: true,
    dv: true,
    hu: true,
    nz: true,
    dw: true,
    wb: true,
    wi: true,
    fr: true
  };
  /* eslint-enable sort-keys */

  try {
    if (getWindowsVersion(navigator.userAgent) < WINDOWS_VERSION_CUTOFF) {
      showCriteria.wv = false;
    }
  } catch (error) {
    showCriteria.wv = false;
  }

  const criteria = getPageCriteria();

  if (!criteria || !isTrueOrTrueString(criteria.desktopDownloadPromptEnabled)) {
    showCriteria.co = false;
  }

  const pageMeta = getPageMetadata();

  if (pageMeta.renderPlatform !== 'desktop') {
    showCriteria.dk = false;
  }

  if (criteria.isMobile || criteria.isTablet) {
    showCriteria.dv = false;
  }

  try {
    // If the Hulk prompt has not been dealt with, don't show this prompt
    if (Notification.permission === 'default') {
      showCriteria.hu = false;
    }
  } catch (error) {
    showCriteria.hu = false;
  }

  try {
    if (criteria.showNewzitNotificationPrompt && !hasActionedNewzitPrompt() && seenNewzitPromptWithin(ONE_DAY_MS)) {
      showCriteria.nz = false;
    }

    const widgetStatus = getFromStorage(KEY_STATUS);
    const widgetLastSeen = getFromStorage(KEY_LAST_SEEN);

    // Has it been seen before?
    if (widgetStatus && widgetLastSeen) {
      switch (widgetStatus) {
      case STATUS_ALLOWED:
        // Assume the download has completed, and don't reshow the prompt
        showCriteria.dw = false;
        break;
      case STATUS_BLOCKED:
        // Don't show if we're within the relevant waiting period
        showCriteria.wb = !inWaitPeriod(widgetLastSeen, BLOCKED_WIDGET_WAIT_PERIOD);
        break;
      case STATUS_DEFAULT:
        // As above
        showCriteria.wi = !inWaitPeriod(widgetLastSeen, IGNORED_WIDGET_WAIT_PERIOD);
        break;
      }
    }

    // One key is set but not the other. This should never be the case. Handle it by resetting both, and don't show the widget
    if (!widgetStatus && widgetLastSeen || widgetStatus && !widgetLastSeen) {
      showCriteria.fr = false;
    }
  } catch (error) {
    showCriteria.nz = false;
  }

  return showCriteria;
};

export const getShowCriteriaString = () => {
  try {
    const showCriteria = getShowCriteria();

    return Object.keys(showCriteria).map((key) => `${key}=${showCriteria[key] ? '1' : '0'}`).join(',');
  } catch (error) {
    return null;
  }
};
