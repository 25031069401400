import {later} from '@mol-fe/mol-fe-async';
import {logger} from '@mol-fe/mol-fe-client-logger';
import Blazy from './lib/blazy';
import createIntervalIterator from './createIntervalIterator';
import waitFor from './waitFor';

const ITERATION_INTERVAL = 100;
const LOAD_OFFSET = 250;
const MOBILE_CONTAINER_CLASS = '#mobile-content > .scrollable-content';
const IMAGE_SELECTOR = 'img[data-src], span[data-src]';

const onLazyLoadError = function (ele, msg) {
  if (msg === 'invalid' && ele.getAttribute('data-src-placeholder')) {
    ele.setAttribute('src', ele.getAttribute('data-src-placeholder'));
  }
};

const blazy = new Blazy({
  error: onLazyLoadError,
  offset: LOAD_OFFSET,
  selector: IMAGE_SELECTOR,
  validateDelay: 50
});

const isMobile = () => document.body.classList.contains('mol-mobile');
const reloadBlazyForMobile = () => {
  if (typeof blazy.mobileMode !== 'boolean' && document.body) {
    // identify mobile mode as soon as the body is available
    blazy.mobileMode = isMobile();
    blazy.mobileModeLoaded = false;
  }

  if (blazy.mobileMode &&
    !blazy.mobileModeLoaded &&
    document.querySelector(MOBILE_CONTAINER_CLASS)) {
    // reload blazy as soon as the mobileContainer is available
    blazy.destroy();
    blazy.options.containerClass = MOBILE_CONTAINER_CLASS;
    blazy.options.container = document.querySelectorAll(blazy.options.containerClass);
    blazy.mobileModeLoaded = true;
    logger.debug('LazyLoad: switch to mobile mode');
  }
};

const reloadBlazyForFeaturedArticles = () => {
  if (window.PageCriteria && window.PageCriteria.articleType === 'feature' && !isMobile()) {
    blazy.destroy();
    blazy.options.containerClass = '#page-container';
    blazy.options.container = document.querySelectorAll(blazy.options.containerClass);
    blazy.revalidate();
  }
};

// While the dom is being loaded we constantly run blazy.revalidate()
// to ensure that all the images in the initial viewport are loaded immediatly
const cancelInterval = createIntervalIterator(() => {
  reloadBlazyForMobile();
  blazy.revalidate();
}, ITERATION_INTERVAL);

const revalidateRotators = function (event) {
  const delegateTarget = event.target.closest('.rotator, .video_carousel_container, [data-mol-fe-rotator]');

  if (delegateTarget) {
    blazy.load(delegateTarget.querySelectorAll(IMAGE_SELECTOR), true);
  }
};

later('DOM_READY', async () => {
  const areDOMImgsReady = () => {
    const container = document.querySelector(isMobile() ? MOBILE_CONTAINER_CLASS : 'body');
    const imgs = Array.from(container.querySelectorAll(IMAGE_SELECTOR)).slice(5);

    return imgs.every(({offsetHeight, offsetWidth}) => offsetWidth > 0 && offsetHeight > 0);
  };

  await waitFor(areDOMImgsReady, 100);
  later.go('DOM_IMGS_READY');
});

later('DOM_IMGS_READY', () => {
  cancelInterval();
  reloadBlazyForFeaturedArticles();
  reloadBlazyForMobile();
  blazy.revalidate();

  // Carousel elements use setInterval to animate content rotation.
  // Since there is no way to attach to an arbitrary setInterval
  // that the carousel is using, this script loads all the images
  // in the carousel upon first user interactive with the carousel.
  // As far as my testing goes, there are two types of carousels:
  // .rotator and .video_carousel_module_wrapper.
  // Some carousels are generated dynamically. Therefore, using delegation.
  document.body.addEventListener('click', revalidateRotators);

  // the following listeners can be removed after mol-fe-rotator takes care of calling
  // DM.molFeLazyLoadImages.revalidate() on user pagination interaction
  document.body.addEventListener('mouseup', revalidateRotators);
  document.body.addEventListener('touchend', revalidateRotators);
});

const load = blazy.load.bind(blazy);
const revalidate = blazy.revalidate.bind(blazy);

export {blazy, load, revalidate};
