export const toLowerCase = (obj) => {
  if (typeof obj !== 'object' || obj === null || Array.isArray(obj)) {
    if (typeof obj === 'string') {
      return obj.toLowerCase();
    }

    return obj;
  }

  const newObj = {};

  Object.keys(obj).forEach((key) => {
    newObj[key] = toLowerCase(obj[key]);
  });

  return newObj;
};

export const trimAllSpaces = (string) => string && string.replace(/\s+/g, '');

export const deleteFalsyValues = (obj) => {
  for (const key of Object.keys(obj)) {
    if (!obj[key]) {
      delete obj[key];
    }
  }

  return obj;
};

export const prependSlash = (str) => {
  if (typeof str === 'string' && !str.startsWith('/')) {
    return `/${str.trim()}`;
  }

  return str;
};
