import {getPageCriteria, getPageMetadata} from '@mol-fe/mol-fe-page-metadata';
import {addUsedArticleIds} from './api';

const numberToThousandReadable = (num) => {
  if (num < 1000) {
    return num;
  }

  return `${(num / 1000).toFixed(1).replace(/\.0$/, '')}k`;
};

const buildArticle = (article, instanceIndex = 0) => {
  const {imgUrl, url, headline, viewCountTrending} = article;
  const linkUrl = `${url}?ico=related-replace${instanceIndex > 0 ? `-${instanceIndex + 1}` : ''}`;

  return `
    <a class="related-replace-article" href="${linkUrl}">
      <div class="related-replace-image">
        <img src="${imgUrl}" alt="" width="636" height="382" loading="lazy" />
      </div>
      <div class="related-replace-info">
        <div class="related-replace-headline">${headline}</div>
        ${viewCountTrending ? `
        <div class="related-replace-count-wrap">
          <div class="related-replace-count wocc">${numberToThousandReadable(viewCountTrending)} viewing now</div>
        </div>
        ` : ''}
      </div>
    </a>
  `;
};

const ARTICLE_COUNT_DESKTOP_XWIDE = 5;
const ARTICLE_COUNT_DEFAULT = 3;

const getArticleCount = () => {
  const {renderPlatform} = getPageMetadata();
  const {articleStyle} = getPageCriteria();
  const articleCount = renderPlatform === 'desktop' && articleStyle === 'extraWide' ?
    ARTICLE_COUNT_DESKTOP_XWIDE :
    ARTICLE_COUNT_DEFAULT;

  return articleCount;
};

const buildWidget = (articles, instanceIndex = 0, topicGroup = null) => {
  const articleCount = getArticleCount();
  const outerWidget = document.createElement('div');
  const widget = document.createElement('div');

  widget.classList.add('mol-fe-related-replace');
  widget.classList.add('ccox');
  widget.dataset.trackModule = `related-replace${instanceIndex > 0 ? `-${instanceIndex + 1}` : ''}`;

  if (articleCount === ARTICLE_COUNT_DESKTOP_XWIDE) {
    widget.classList.add('related-replace-desktop-xwide');
  }

  if (topicGroup === 'royals') {
    outerWidget.classList.add('royal_wedding');
  }

  widget.innerHTML = `
    <div class="related-replace-title wocc">${instanceIndex > 0 ? 'MORE ' : ''}TRENDING</div>
    <div class="related-replace-articles">
      ${articles.slice(0, articleCount).map((article) => buildArticle(article, instanceIndex)).join('')}
    </div>
  `;

  outerWidget.appendChild(widget);

  return outerWidget;
};

export const clearPlaceHolder = (relatedElement) => {
  if (relatedElement && relatedElement.classList.contains('related-replace-placeholder')) {
    relatedElement.remove();
  }
};

export const replaceRelated = ({relatedElement, articles, instanceIndex = 0, topicGroup = null}) => {
  const articleCount = getArticleCount();

  if (!relatedElement || !articles || !articles.length || articles.length < articleCount) {
    clearPlaceHolder(relatedElement);

    return;
  }

  const widget = buildWidget(articles, instanceIndex, topicGroup);

  relatedElement.replaceWith(widget);

  addUsedArticleIds(articles.slice(0, articleCount).map((article) => article.articleId));
};
