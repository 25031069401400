import ted from '../ted';

const getEventNamePrefix = () => {
  const segmentationApplies = window.DM.molFePaywall && 'isInterstitialFlow' in window.DM.molFePaywall;

  if (!segmentationApplies) {
    return '';
  }

  const {isInterstitialFlow} = window.DM.molFePaywall;

  return isInterstitialFlow ? 'int_' : 'noint_';
};

const handleGenericAdFreeEvent = (eventName) => {
  ted.tedEvent('page_event', {
    action: 'set',
    name: `adfree_${getEventNamePrefix()}${eventName}`,
    value: Date.now()
  });
};

const createHandler = (eventName) => () => handleGenericAdFreeEvent(eventName);

const adFree = {
  AD_FREE_BANNER_CLICK: createHandler('banner_click'),
  AD_FREE_BANNER_DISMISS: createHandler('banner_dismiss'),
  AD_FREE_BANNER_IMPRESSION: createHandler('banner_display'),
  AD_FREE_BANNER_LOGIN_CLICK: createHandler('banner_login'),
  AD_FREE_CANCEL_CONFIRM: createHandler('subscription_showcancellation'),
  AD_FREE_CANCEL_SUCCESS: createHandler('subscription_cancelled'),
  AD_FREE_INTERSTITIAL_CLICK: createHandler('interstitial_click'),
  AD_FREE_INTERSTITIAL_DISMISS: createHandler('interstitial_dismiss'),
  AD_FREE_INTERSTITIAL_IMPRESSION: createHandler('interstitial_display'),
  AD_FREE_LOGIN_CREATE_ACCOUNT: createHandler('login_createaccount'),
  AD_FREE_LOGIN_SUCCESS_INTO_SUBSCRIBE: createHandler('login_successintosubscribe'),
  AD_FREE_MAX_DEVICES_CONTINUE: createHandler('subscription_continuewithads'),
  AD_FREE_MAX_DEVICES_DIALOG: createHandler('subscription_maxdevicesreached'),
  AD_FREE_MAX_DEVICES_MANAGE: createHandler('subscription_managedevices'),
  AD_FREE_PAYMENT_LAPSED: createHandler('subscription_paymentlapsed'),
  AD_FREE_SUBSCRIBE_BRAINTREE_INIT_ERROR: createHandler('subscribe_braintreeiniterror'),
  AD_FREE_SUBSCRIBE_DISMISS: createHandler('subscribe_dismiss'),
  AD_FREE_SUBSCRIBE_LOGIN_CLICK: createHandler('subscribe_logintext'),
  AD_FREE_SUBSCRIBE_LOGIN_DISMISS: createHandler('subscribe_logindismiss'),
  AD_FREE_SUBSCRIBE_OTP_SHOW: createHandler('subscribe_verify'),
  AD_FREE_SUBSCRIBE_PAYMENT_ERROR: createHandler('subscribe_paymentfailure'),
  AD_FREE_SUBSCRIBE_REGISTRATION_ERROR: createHandler('subscribe_registrationfailure'),
  AD_FREE_SUBSCRIBE_SUBMIT: createHandler('subscribe_click'),
  AD_FREE_SUBSCRIBE_SUCCESS: createHandler('subscribe_success')
};

export default adFree;
