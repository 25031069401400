import {PACKAGE_NAME} from '../../../../../config';

export const FILENAME = 'removeXpModuleStylesFromHead';

const removeXpModuleStylesFromHead = (styles) => {
  if (!Array.isArray(styles)) {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.invalidProps`, {
        styles,
      }
    );

    return null;
  }

  try {
    for (const style of styles) {
      const link = document.querySelector(`head link[href="${style}"]`);

      if (link) {
        link.remove();
      }
    }

    return true;
  } catch (error) {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.thrownError`,
      {
        error,
        styles,
      }
    );

    return null;
  }
};

export default removeXpModuleStylesFromHead;
