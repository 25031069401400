import {TIMEOUT_IN_MILLISECONDS, PACKAGE_NAME} from '../../../../../config';
import createScriptFromUrl from './utils/createScriptFromUrl';

export const FILENAME = 'addXpModuleScriptsToHead';

// tries to add the xpModule scripts to the head of the document
// returns a promise that resolves when the scripts are added to the head or when there is an error
// if the scripts are added to the head, the promise resolves the state of every script
// if there are any error, the promise resolves null
const addXpModuleScriptsToHead = (scripts) => new Promise((resolve) => {
  const state = {
    added: [],
    errored: [],
    loaded: [],
  };

  if (!Array.isArray(scripts)) {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.invalidProps`, {
        scripts,
        state,
      }
    );

    return resolve(null);
  }

  if (scripts.length === 0) {
    return resolve(state);
  }

  let isResolved = false;

  const timeout = setTimeout(() => {
    if (isResolved) {
      return undefined;
    }

    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.timeout`, {
        scripts,
        state,
        TIMEOUT_IN_MILLISECONDS
      }
    );

    isResolved = true;

    return resolve(null);
  }, TIMEOUT_IN_MILLISECONDS);

  const onLoad = (event) => {
    state.loaded.push(event.target.src);

    if (state.loaded.length === scripts.length) {
      clearTimeout(timeout);
      isResolved = true;

      return resolve(state);
    }

    return undefined;
  };

  const onError = (event) => {
    if (isResolved) {
      return undefined;
    }

    state.errored.push(event.target.src);

    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.onError`, {
        erroredScript: event.target.src,
        scripts,
        state,
      });

    clearTimeout(timeout);
    isResolved = true;

    return resolve(null);
  };

  for (const url of scripts) {
    const script = createScriptFromUrl(url, onLoad, onError);

    if (!(script instanceof HTMLScriptElement)) {
      console.error(
        `[${PACKAGE_NAME}] ${FILENAME}.createScriptFromUrl`, {
          onError,
          onLoad,
          script,
          state,
          url,
        }
      );

      clearTimeout(timeout);
      isResolved = true;

      return resolve(null);
    }

    document.head.appendChild(script);

    state.added.push(url);
  }

  return undefined;
});

export default addXpModuleScriptsToHead;
