// https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/replaceWith
import createDocumentFragment from '../utils/createDocumentFragment';

const replaceWith = () => {
  const prototypes = [Element.prototype, CharacterData.prototype, DocumentType.prototype];

  prototypes.forEach((prototype) => {
    if (typeof prototype.replaceWith !== 'function') {
      prototype.replaceWith = function (...elements) {
        const documentFragment = createDocumentFragment(elements);

        this.parentNode.replaceChild(documentFragment, this);
      };
    }
  });
};

export default replaceWith;
