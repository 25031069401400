import {event} from '../gtag';

const createHandler = (category, action, nonInteraction) => (_, data) => {
  const {label} = data;

  event(action, {
    // eslint-disable-next-line id-match
    event_category: category,
    // eslint-disable-next-line id-match
    event_label: label,
    // eslint-disable-next-line id-match
    non_interaction: Boolean(nonInteraction),
    typeofarticlehero: label
  });
};

export const timArticleHeroHandlers = {
  ARTICLE_HERO_AUTHOR_TWITTER_LINK_CLICK: createHandler('articleHero', 'click'),
  ARTICLE_HERO_LOZENGE_LINK_CLICK: createHandler('articleHero', 'click')
};
