// https://developer.mozilla.org/en-US/docs/Web/API/ParentNode/append
import createDocumentFragment from '../utils/createDocumentFragment';

const append = () => {
  const prototypes = [Element.prototype, CharacterData.prototype, DocumentType.prototype];

  prototypes.forEach((prototype) => {
    if (typeof prototype.append !== 'function') {
      prototype.append = function (...elements) {
        const documentFragment = createDocumentFragment(elements);

        this.appendChild(documentFragment);
      };
    }
  });
};

export default append;
