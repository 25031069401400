import {event} from '../gtag';

const createHandler = (category, action, nonInteraction) => () => {
  event(action, {
    // eslint-disable-next-line id-match
    event_category: category,
    // eslint-disable-next-line id-match
    non_interaction: Boolean(nonInteraction)
  });
};

export const adFreeHandlers = {
  AD_FREE_BANNER_CLICK: createHandler('adfree banner', 'click'),
  AD_FREE_BANNER_DISMISS: createHandler('adfree banner', 'dismiss'),
  AD_FREE_BANNER_IMPRESSION: createHandler('adfree banner', 'display', true),
  AD_FREE_BANNER_LOGIN_CLICK: createHandler('adfree banner', 'login'),
  AD_FREE_CANCEL_CONFIRM: createHandler('adfree subscription', 'showcancellation'),
  AD_FREE_CANCEL_SUCCESS: createHandler('adfree subscription', 'cancelled', true),
  AD_FREE_MAX_DEVICES_CONTINUE: createHandler('adfree subscription', 'continuewithads'),
  AD_FREE_MAX_DEVICES_DIALOG: createHandler('adfree subscription', 'maxdevicesreached', true),
  AD_FREE_MAX_DEVICES_MANAGE: createHandler('adfree subscription', 'managedevices'),
  AD_FREE_PAYMENT_LAPSED: createHandler('adfree subscription', 'paymentlapsed', true),
  AD_FREE_SUBSCRIBE_BRAINTREE_INIT_ERROR: createHandler('adfree subscription', 'braintreeiniterror', true),
  AD_FREE_SUBSCRIBE_DISMISS: createHandler('adfree subscribe', 'dismiss'),
  AD_FREE_SUBSCRIBE_LOGIN_CLICK: createHandler('adfree subscribe', 'logintext'),
  AD_FREE_SUBSCRIBE_LOGIN_DISMISS: createHandler('adfree subscribe', 'logindismiss'),
  AD_FREE_SUBSCRIBE_OTP_SHOW: createHandler('adfree subscribe', 'verify', true),
  AD_FREE_SUBSCRIBE_PAYMENT_ERROR: createHandler('adfree subscribe', 'paymentfailure', true),
  AD_FREE_SUBSCRIBE_REGISTRATION_ERROR: createHandler('adfree subscribe', 'registrationfailure', true),
  AD_FREE_SUBSCRIBE_SUBMIT: createHandler('adfree subscribe', 'click'),
  AD_FREE_SUBSCRIBE_SUCCESS: createHandler('adfree subscribe', 'success', true)
};
