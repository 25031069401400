import {event} from '../gtag';

const createHandler = (category, action) => (_, data) => {
  let eventProperties = {
    // eslint-disable-next-line id-match
    event_category: category,
    // eslint-disable-next-line id-match
    event_label: data.searchTerm,
    // eslint-disable-next-line id-match
    non_interaction: false,
    searchTerm: data.searchTerm
  };

  if (action === 'site search') {
    eventProperties = {
      ...eventProperties,
      internalSearchIncrease: 1,
      internalSearchTerms: data.searchTerm
    };
  }

  event(action, eventProperties);
};

export const searchHandlers = {
  SITE_SEARCH: createHandler('search', 'site search'),
  WEB_SEARCH: createHandler('search', 'web search')
};
