import {event} from '../gtag';

const createHandler = (category, action, nonInteraction) => () => {
  event(action, {
    // eslint-disable-next-line id-match
    event_category: category,
    // eslint-disable-next-line id-match
    non_interaction: Boolean(nonInteraction)
  });
};

export const swipeHandlers = {
  SWIPE_TUTORIAL_CLOSE: createHandler('swipe tutorial', 'close'),
  SWIPE_TUTORIAL_SHOWN: createHandler('swipe tutorial', 'shown', true)
};
