/* eslint-disable complexity */
import axios from 'axios';
import {getPageCriteria, getPageMetadata} from '@mol-fe/mol-fe-page-metadata';
import {isArticleInRead} from '@mol-fe/mol-fe-article-read';

const API_BASE_URL = '/api/mol-fe-feeds/v2/articles/rankedByChannelReferrer';
const API_SIMILAR_URL = '/api/mol-fe-feeds/v2/articles/rankedSimilar';
const API_TOPIC_GROUP_URL = '/api/mol-fe-feeds/v2/articles/rankedByTopicGroup';

const API_SUFFIX_BY_GEO = {
  AU: '/auhome',
  GB: '/home',
  US: '/ushome'
};

const MAX_ARTICLES = 5;

let usedArticleIds = [];

export const addUsedArticleIds = (articleIds) => {
  usedArticleIds = usedArticleIds.concat(articleIds);
};

export const getRankedArticles = async ({useSimilarArticles = false, topicGroup = null}) => {
  const {articleId, geo, channel} = getPageCriteria();
  const {topics} = getPageMetadata();
  const topicsSuffix = topics && topics.length ? 'hasTopics' : 'noTopics';
  const suffix = API_SUFFIX_BY_GEO[geo] || API_SUFFIX_BY_GEO.GB;
  let url = `${API_BASE_URL}${suffix}`;

  if (useSimilarArticles) {
    url = `${API_SIMILAR_URL}/${articleId}/${topicsSuffix}`;
  } else if (topicGroup) {
    url = `${API_TOPIC_GROUP_URL}/${encodeURIComponent(topicGroup)}`;
  }

  const response = await axios.get(url);

  if (!response || !response.data || !response.data.articles) {
    throw new Error('Invalid ranked articles response');
  }

  const {articles} = response.data;
  const sameChannel = articles.filter((article) => article.url.startsWith(`/${channel}`));
  const otherChannels = articles.filter((article) => !article.url.startsWith(`/${channel}`));
  const rankedArticles = sameChannel.concat(otherChannels)
    .filter((article) => !isArticleInRead(article.articleId) && article.articleId !== articleId)
    .filter((article) => article.url && article.imgUrl && article.headline)
    .filter((article) => !usedArticleIds.includes(article.articleId))
    .slice(0, MAX_ARTICLES);

  if (rankedArticles.length < MAX_ARTICLES && (useSimilarArticles || topicGroup)) {
    const rankedArticleIds = rankedArticles.map((article) => article.articleId);
    const nonReleatedRankedArticles = await getRankedArticles({
      topicGroup: null,
      useSimilarArticles: false
    });

    for (let idx = 0; rankedArticles.length < MAX_ARTICLES && idx < nonReleatedRankedArticles.length; idx++) {
      const article = nonReleatedRankedArticles[idx];

      if (!rankedArticleIds.includes(article.articleId)) {
        rankedArticles.push(article);
      }
    }
  }

  return rankedArticles;
};
