import jsCookie from 'js-cookie';

export const getClientId = () => {
  try {
    const clientIdRaw = jsCookie.get('_ga');
    const clientId = clientIdRaw && clientIdRaw.split('.').slice(-2).join('.');

    return clientId;
  } catch (error) {
    return undefined;
  }
};
