import {event} from '../gtag';

const createHandler = (category, action, nonInteraction) => (_, data) => {
  event(action, {
    // eslint-disable-next-line id-match
    event_category: category,
    // eslint-disable-next-line id-match
    event_label: data && data.promptStatus,
    // eslint-disable-next-line id-match
    non_interaction: Boolean(nonInteraction),
    promptStatus: data && data.promptStatus,
    windowsPushStatus: data && data.userNotificationStatus
  });
};

export const windowsNotificationWidgetHandlers = {
  WINDOWS_WIDGET_PROMPT_CLICK_ALLOW: createHandler('widget push notification', 'prompt choice'),
  WINDOWS_WIDGET_PROMPT_CLICK_BLOCK: createHandler('widget push notification', 'prompt choice'),
  WINDOWS_WIDGET_PROMPT_CLICK_CLOSE: createHandler('widget push notification', 'prompt choice'),
  WINDOWS_WIDGET_PROMPT_OPEN: createHandler('widget push notification', 'prompt impression', true)
};
