import {event} from '../gtag';

const createHandler = (category, action, nonInteraction) => () => {
  event(action, {
    // eslint-disable-next-line id-match
    event_category: category,
    // eslint-disable-next-line id-match
    non_interaction: Boolean(nonInteraction)
  });
};

export const mobileMenuHandlers = {
  MOBILE_MENU_COMMENT: createHandler('mobile menu', 'comment'),
  MOBILE_MENU_DESKTOP_SITE: createHandler('mobile menu', 'desktop site')
};
