import {event} from '../gtag';

const createHandler = (category, action, nonInteraction) => (_, data) => {
  const {company} = data;

  event(action, {
    companyName: company,
    // eslint-disable-next-line id-match
    event_category: category,
    // eslint-disable-next-line id-match
    event_label: company,
    // eslint-disable-next-line id-match
    non_interaction: Boolean(nonInteraction)
  });
};

export const companyReviewHandlers = {
  COMPANY_REVIEW_IMPRESSION: createHandler('companyReview', 'impression', true),
  COMPANY_REVIEW_LEARN_MORE_CLICKED: createHandler('companyReview', 'click'),
  COMPANY_REVIEW_LESS_CONTENT_CLICKED: createHandler('companyReview', 'click'),
  COMPANY_REVIEW_MORE_CONTENT_CLICKED: createHandler('companyReview', 'click')
};
