import {later} from '@mol-fe/mol-fe-async';
import {scrollUtils} from '@mol-fe/mol-fe-dom-helpers';
import {logger} from '@mol-fe/mol-fe-client-logger';

later('DOM_READY').then(() => {
  const elements = document.body.querySelectorAll('a[href="#top"]:not(.back-to-top)');
  const elementToScroll = document.body.querySelector('.scrollable-content') || window;

  Array.from(elements).forEach((element) => {
    element.addEventListener('click', (event) => {
      event.preventDefault();
      scrollUtils.scroll(elementToScroll, 0);
    });
  });

  return null;
}).catch((error) => logger.error('There was an error loading scrollToTop extension', error));
