import {isWebPushSupported} from '@mol-fe/mol-fe-web-push-browser-register';
import {event} from '../gtag';

const getWebPushStatus = function () {
  if (isWebPushSupported()) {
    switch (Notification.permission) {
    case 'default': {
      return 'eligible';
    }
    case 'denied': {
      return 'denied';
    }
    case 'granted': {
      return 'subscribed';
    }
    }
  }

  return 'not_supported';
};

const getCommonProperties = (data) => {
  const properties = {
    ...data,
    // eslint-disable-next-line id-match
    event_category: 'push notification',
    webPushStatus: getWebPushStatus()
  };

  delete properties.__numberOfAttempts;
  delete properties.channel;
  delete properties.subChannel;

  return JSON.parse(JSON.stringify(properties));
};

const handleWebPushPromptImpressionEvent = function (_, data) {
  event('prompt impression', {
    ...getCommonProperties(data),
    // eslint-disable-next-line id-match
    non_interaction: true
  });
};

const handleWebPushPromptClickEvent = function (_, data) {
  const promptChoice = data.permission === 'default' ? 'closed' : data.permission;

  event('prompt choice', {
    ...getCommonProperties(data),
    // eslint-disable-next-line id-match
    event_label: promptChoice,
    // eslint-disable-next-line id-match
    non_interaction: false,
    promptChoice
  });
};

const handleWebPushNotificationEvent = function (_, data) {
  const pushNotificationId = `${data.campaignId}:${data.subscriptionId}`;

  event('click', {
    ...getCommonProperties(data),
    // eslint-disable-next-line id-match
    event_label: pushNotificationId,
    // eslint-disable-next-line id-match
    non_interaction: false,
    pushNotificationId,
    webPushCampaignId: data.campaignId
  });
};

const handleWebPullNotificationEvent = function (_, data) {
  const properties = {
    ...data,
    // eslint-disable-next-line id-match
    event_category: 'pull notification'
  };

  delete properties.__numberOfAttempts;
  delete properties.channel;
  delete properties.subChannel;

  event('click', {
    ...properties,
    // eslint-disable-next-line id-match
    event_label: `${data.campaignId}:${data.crossOrgUserId}`,
    // eslint-disable-next-line id-match
    non_interaction: false,
    webPullCampaignId: data.campaignId
  });
};

export const webPushHandlers = {
  WEB_PULL_NOTIFICATION_CLICK: handleWebPullNotificationEvent,
  WEB_PUSH_NOTIFICATION_CLICK: handleWebPushNotificationEvent,
  WEB_PUSH_PROMPT_CLICK: handleWebPushPromptClickEvent,
  WEB_PUSH_PROMPT_IMPRESSION: handleWebPushPromptImpressionEvent
};
