const STORAGE_PREFIX = 'mol-fe-reorder-mobile-channel-';

export const getFromStorage = (key) => {
  try {
    if (!('localStorage' in window)) {
      return null;
    }

    const rawValue = window.localStorage.getItem(`${STORAGE_PREFIX}${key}`);

    if (rawValue) {
      return JSON.parse(rawValue);
    }
  } catch (error) {
    // Ignore failures
  }

  return null;
};

export const setToStorage = (key, value) => {
  try {
    if (!('localStorage' in window)) {
      return;
    }

    window.localStorage.setItem(`${STORAGE_PREFIX}${key}`, JSON.stringify(value));
  } catch (error) {
    // Ignore
  }
};
