// https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/remove
const remove = () => {
  const prototypes = [Element.prototype, CharacterData.prototype, DocumentType.prototype];

  prototypes.forEach((prototype) => {
    if (typeof prototype.remove !== 'function') {
      prototype.remove = function () {
        this.parentNode.removeChild(this);
      };
    }
  });
};

export default remove;
