/* eslint-disable lines-around-comment, id-match */
export const UA_ID = 'UA-3639451-11';
export const GA4_ID = 'G-XE0XLFFF16';
export const GTAG_SCRIPT_URL = `https://www.googletagmanager.com/gtag/js?id=${UA_ID}&l=molFeDataLayer`;
export const FBIA_FORCED_REFERRER = 'http://fbia.facebook.com/';
export const CUSTOM_DIMENSION_MAP = {
  dimension1: 'renderPlatform',
  dimension2: 'loggedInStatus',
  dimension3: 'authorName',
  dimension4: 'contentType',
  dimension5: 'articleId',
  dimension6: 'channel',
  dimension7: 'articleTitle',
  dimension8: 'publishedDate',
  dimension9: 'domain',
  dimension10: 'sponsorName',
  dimension11: 'articleAge',
  dimension12: 'wordCount',
  dimension13: 'publishedHour',
  dimension14: 'subChannel',
  dimension15: 'subChannel2',
  dimension16: 'subChannel3',
  dimension17: 'gdprStatus',
  dimension18: 'galleryImageAction',
  dimension19: 'galleryImageSwipeCount',
  dimension20: 'galleryImageId',
  dimension21: 'userAgent',
  dimension22: 'pageUrl',
  dimension23: 'internalSearchTerms',
  dimension24: 'referrer',
  dimension25: 'icoInternalHit',
  dimension26: 'icoInternalSession',
  dimension27: 'videoDuration',
  dimension28: 'videoPlayerVersion',
  dimension29: 'videoChannelArticleId',
  dimension30: 'videoPlayerType',
  dimension31: 'videoModule',
  dimension32: 'videoAdsEnabled',
  dimension33: 'videoInternalId',
  dimension34: 'videoTitle',
  dimension35: 'videoExternalId',
  dimension36: 'userLocalServerDate',
  // dimension37: 'pageContainsVideoPlayerTypes',
  dimension38: 'clientId',
  dimension39: 'partnerSite',
  dimension40: 'itoExternalHit',
  dimension41: 'itoExternalSession',
  dimension42: 'autoRefreshStatus',
  // dimension43: '__UNUSED__percentOfPagePrevious',
  // dimension44: 'previousPagename',
  dimension45: 'windowsPushStatus',
  dimension46: 'referringUrl',
  dimension47: 'windowsPushCriteriaString',
  dimension48: 'webPushCampaignId',
  dimension49: 'webPushStatus',
  dimension50: 'bundleVersions',
  dimension51: 'adBlockerEnabled',
  dimension52: 'userDetails',
  dimension53: 'mobileHomePreference',
  dimension54: 'moduleTracking',
  dimension55: 'fffActions',
  dimension56: 'fffLayout',
  dimension57: 'dmtvEpisodeId',
  dimension58: 'dmtvAirDate',
  // dimension59: 'moduleTypesTopNav',
  // dimension60: 'moduleTypesBody',
  // dimension61: 'moduleTypesArticle',
  // dimension62: 'moduleTypesFooter',
  // dimension63: 'articleFindingMethod',
  // dimension64: 'brandDomain',
  // dimension65: 'destinationUrl',
  // dimension66: 'parentArticleTitle',
  dimension67: 'topics',
  dimension93: 'swipeFunnelStatus',
  dimension94: 'applePrivateRelay',
  dimension95: 'adFreeUserStatus',
  dimension96: 'adFreeVersionStatus',
  dimension97: 'adFreeDeviceNumber',
  dimension98: 'referringDomain',
  dimension100: 'visibilityState',
  dimension101: 'multivariantExperiments',
  dimension112: 'openwebEnabled',
  dimension113: 'webPullCampaignId',
  dimension114: 'mobileHomeTabChoice',
  dimension115: 'moduleName',
  dimension116: 'moduleListPosition',

  // ...
  metric1: 'internalSearchIncrease',
  metric2: 'articleViewIncrease',
  metric3: 'autoRefreshIncrease',
  metric4: 'imageViewIncrease',
  metric5: 'videoViewIncrease',
  metric7: 'video25Increase',
  metric8: 'video50Increase',
  metric9: 'videoCompleteIncrease',
  metric10: 'videoAdvertStartIncrease',
  metric11: 'socialShareClicksIncrease',
  // ...
  metric20: 'timeSpentActiveChannel',
  metric21: 'timeSpentActiveArticle',
  metric22: 'activitySittingsChannel',
  metric23: 'activitySittingsArticle'
};
