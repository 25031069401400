import {PACKAGE_NAME} from '../../config';
import addOnClickEventListenerToXpModuleNode from './addOnClickEventListenerToXpModuleNode/addOnClickEventListenerToXpModuleNode';
import addViewableListenerToXpModuleNode from './addViewableListenerToXpModuleNode/addViewableListenerToXpModuleNode';
import addXpModuleToPromotionalSlot from './addXpModuleToPromotionalSlot/addXpModuleToPromotionalSlot';
import publishTrackingEvent from './publishTrackingEvent/publishTrackingEvent';

export const FILENAME = 'onPromotionalSlotMessage';

const onPromotionalSlotMessage = async (data) => {
  if (!data) {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.invalidProps`,
      {data}
    );

    return null;
  }

  try {
    const xpModuleNode = await addXpModuleToPromotionalSlot(data);

    if (xpModuleNode === null) {
      console.error(
        `[${PACKAGE_NAME}] ${FILENAME}.addXpModuleToPromotionalSlot`,
        {data}
      );

      return null;
    }

    const xpModuleName = data.xpModule.name;
    const campaignId = data.campaignId;

    publishTrackingEvent({
      campaignId,
      eventName: 'impression',
      xpModuleName
    });

    addOnClickEventListenerToXpModuleNode(xpModuleNode, xpModuleName, campaignId);
    addViewableListenerToXpModuleNode(xpModuleNode, xpModuleName, campaignId);

    return true;
  } catch (error) {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.thrownError`,
      {
        data,
        error,
      }
    );

    return null;
  }
};

export default onPromotionalSlotMessage;
