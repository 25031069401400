import {PACKAGE_NAME} from '../../../../../config';
import publishTrackingEvent from '../../../publishTrackingEvent/publishTrackingEvent';

export const VIEWABLE_EVENT_NAME = 'viewable';
export const FILENAME = 'onXpModuleIsVisibleForTheFirstTime';

const onXpModuleIsVisibleForTheFirstTime = (xpModuleName, campaignId) => {
  if (typeof xpModuleName !== 'string') {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.invalidProps`,
      {
        xpModuleName,
      }
    );

    return null;
  }

  try {
    publishTrackingEvent({
      campaignId,
      eventName: VIEWABLE_EVENT_NAME,
      xpModuleName,
    });

    return true;
  } catch (error) {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.thrownError`,
      {
        error,
        xpModuleName
      }
    );

    return null;
  }
};

export default onXpModuleIsVisibleForTheFirstTime;
