import Cookies from 'js-cookie';
import {logger} from '@mol-fe/mol-fe-client-logger';

const STORE_KEY_NAME = 'mol-fe-cookie-reinforcer';

export const addCookieToReinforce = (cookieName, value, expireDate = null) => {
  try {
    if (!('localStorage' in window)) {
      return;
    }

    const currentStore = JSON.parse(window.localStorage.getItem(STORE_KEY_NAME) || '{}');

    currentStore[cookieName] = {
      expireDate,
      value
    };

    window.localStorage.setItem(STORE_KEY_NAME, JSON.stringify(currentStore));
  } catch (error) {
    logger.error('Error adding cookie to reinforce', error);
  }
};

export const stopReinforcingCookie = (cookieName) => {
  try {
    if (!('localStorage' in window)) {
      return;
    }

    const currentStore = JSON.parse(window.localStorage.getItem(STORE_KEY_NAME) || '{}');

    delete currentStore[cookieName];

    window.localStorage.setItem(STORE_KEY_NAME, JSON.stringify(currentStore));
  } catch (error) {
    logger.error('Error removing cookie from reinforcer', error);
  }
};

export const setCookieAndReinforce = (cookieName, value, expireDate = null) => {
  try {
    Cookies.set(cookieName, value, {
      expires: expireDate,
      path: '/'
    });
    addCookieToReinforce(cookieName, value, expireDate);
  } catch (error) {
    logger.error('Error setting cookie and adding to reinforce list', error);
  }
};

export const removeCookieAndStopReinforcing = (cookieName) => {
  try {
    Cookies.remove(cookieName);
    stopReinforcingCookie(cookieName);
  } catch (error) {
    logger.error('Error removing cookie and stopping reinforcement', error);
  }
};

export const reinforceCookies = () => {
  try {
    if (!('localStorage' in window)) {
      return;
    }

    const currentStore = JSON.parse(window.localStorage.getItem(STORE_KEY_NAME) || '{}');
    const newStore = {};

    for (const key of Object.keys(currentStore)) {
      const {expireDate, value} = currentStore[key];
      const expires = expireDate && new Date(expireDate);

      if (!expires || expires.getTime() > Date.now()) {
        Cookies.set(key, value, {
          expires,
          path: '/'
        });

        newStore[key] = {
          expireDate: expires,
          value
        };
      }
    }

    window.localStorage.setItem(STORE_KEY_NAME, JSON.stringify(newStore));
  } catch (error) {
    logger.error('Error reinforcing cookies', error);
  }
};
