// https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
// depends on "matches" polyfill
// eslint-disable-next-line import/no-unassigned-import
import polyfillMatches from './matches';

const closest = () => {
  polyfillMatches();

  if (typeof Element.prototype.closest !== 'function') {
    Element.prototype.closest = function (selector) {
      let currentElement;

      // eslint-disable-next-line consistent-this
      currentElement = this;
      while (currentElement && currentElement.nodeType === 1) {
        if (currentElement.matches(selector)) {
          return currentElement;
        }

        currentElement = currentElement.parentNode;
      }

      return null;
    };
  }
};

export default closest;
