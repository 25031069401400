/* eslint-disable complexity */
import {getPageMetadata, getPageCriteria} from '@mol-fe/mol-fe-page-metadata';
import {url} from '@mol-fe/mol-fe-client-utils';
import {getExperiments} from '@mol-fe/mol-fe-multivariant';
import {getSwipeFunnelStatus} from '../../utils/swipe';
import {getMobileHomeTab} from '../../utils/getMobileHomeTab';
import {getShowCriteriaString} from '../windowsNotificationWidget';
import {FBIA_FORCED_REFERRER} from '../constants';
import {getGdprStatus} from './gdpr';
import {deleteFalsyValues, trimAllSpaces, prependSlash} from './utils';
import {getUserLocalServerDate} from './user';
import {getWebPushStatus} from './webPush';
import {getUserDetails} from './userAuth';
import {getBundleVersions} from './bundles';
import {getArticleAge, getPublishedDateStr, getPublishedHourStr} from './article';
import {getSearchParams} from './pageUrl';
import {getAdFreeDimensions} from './adFree';
import {getModuleTracking} from './moduleClickTracking';
import {getInternalSearchTerms} from './internalSearch';
import {getAutoRefreshStatus} from './autoRefresh';
import {getLastNonInternalReferrer} from './referrer';
import {isApplePrivateRelay} from './applePrivateRelay';
import {getClientId} from './clientId';
import {getPageParamsFromQueryString} from './iframeParams';

const {getHostname} = url;

export const getConfigProps = () => {
  const metadata = getPageMetadata();
  const {
    articleId,
    articleTitle,
    authorName,
    channel,
    contentType,
    domain,
    loggedInStatus,
    partnerSite,
    publishedDate,
    subChannel,
    subChannel2,
    subChannel3,
    topics,
    wordCount
  } = metadata;
  const {
    openwebEnabled,
    pageType,
    sponsored,
    sponsorLabel,
    sponsorName
  } = getPageCriteria();
  let renderPlatform = metadata.renderPlatform;

  renderPlatform = location.href.includes('/embed/gamp-video/') ? 'mobile amp' : renderPlatform;
  renderPlatform = location.href.includes('/embed/fbia-video/') ? 'mobile fbia' : renderPlatform;

  const isSponsored = sponsored === 'true' || sponsored === true;
  const isFBIA = renderPlatform && renderPlatform.toLowerCase() === 'mobile fbia';
  const referrer = isFBIA ? FBIA_FORCED_REFERRER : document.referrer;
  const sessionReferrer = isFBIA && FBIA_FORCED_REFERRER || getLastNonInternalReferrer() || referrer;
  const isArticle = pageType === 'article' || contentType === 'article';
  // eslint-disable-next-line id-match
  const {ico, ito, utm_source} = getSearchParams();
  let campaign;

  if (ito && !utm_source) {
    campaign = {
      medium: 'ito',
      source: ito
    };
  }
  const applePrivateRelay = isApplePrivateRelay();

  window.applePrivateRelay = applePrivateRelay;

  const {moduleName, moduleTracking, moduleListPosition} = getModuleTracking();

  const experiments = getExperiments();
  const adBlockerConversionState = window.adverts && window.adverts.getAdBlockerConversionState && window.adverts.getAdBlockerConversionState() || 'unknown';
  const props = {
    adBlockerEnabled: adBlockerConversionState,
    applePrivateRelay: applePrivateRelay ? 'true' : 'false',
    articleAge: getArticleAge(publishedDate),
    articleId,
    articleTitle,
    authorName,
    autoRefreshStatus: getAutoRefreshStatus(),
    bundleVersions: getBundleVersions(metadata),
    campaign,
    channel: prependSlash(channel),
    clientId: getClientId(),
    // eslint-disable-next-line id-match
    content_group1: contentType,
    contentType,
    domain,
    gdprStatus: getGdprStatus(),
    icoInternalHit: ico,
    icoInternalSession: ico,
    internalSearchTerms: getInternalSearchTerms(),
    itoExternalHit: ito,
    itoExternalSession: ito,
    loggedInStatus: trimAllSpaces(loggedInStatus),
    mobileHomeTabChoice: getMobileHomeTab() || 'undefined',
    moduleListPosition,
    moduleName,
    moduleTracking,
    multivariantExperiments: experiments && experiments.length ? experiments.join(',') : 'undefined',
    openwebEnabled: openwebEnabled ? 'true' : 'false',
    pageUrl: location.href || '',
    partnerSite: partnerSite || undefined,
    publishedDate: getPublishedDateStr(publishedDate),
    publishedHour: getPublishedHourStr(publishedDate),
    referrer,
    referringDomain: sessionReferrer === 'undefined' ? sessionReferrer : getHostname(sessionReferrer),
    referringUrl: sessionReferrer,
    renderPlatform,
    sponsorName: sponsorName || sponsorLabel || isSponsored && 'sponsored' || undefined,
    subChannel: prependSlash(subChannel),
    subChannel2: prependSlash(subChannel2),
    subChannel3: prependSlash(subChannel3),
    swipeFunnelStatus: getSwipeFunnelStatus() || 'undefined',
    topics: topics && topics.length > 0 ? topics.join(',') : undefined,
    userAgent: navigator.userAgent,
    userDetails: getUserDetails(),
    userLocalServerDate: getUserLocalServerDate(),
    webPushStatus: getWebPushStatus(),
    windowsPushCriteriaString: getShowCriteriaString(),
    wordCount: isArticle ? wordCount : undefined,
    ...getAdFreeDimensions(),
    ...getPageParamsFromQueryString() || {}
  };

  for (const dimension of ['itoExternalHit', 'itoExternalSession', 'referringUrl', 'referringDomain']) {
    if (!props[dimension]) {
      props[dimension] = 'undefined';
    }
  }

  return deleteFalsyValues(props);
};
