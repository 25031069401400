import {event} from '../gtag';

const furnitureClickHandler = (_, data) => {
  const furnitureName = data && data.furnitureName;

  if (!furnitureName) {
    return;
  }

  const params = {
    // eslint-disable-next-line id-match
    event_category: 'furniture',
    furnitureName,
    // eslint-disable-next-line id-match
    non_interaction: false
  };

  event(furnitureName, params);
};

export const furnitureHandlers = {
  PAGE_FURNITURE_CLICKED: furnitureClickHandler
};
