import {event} from '../gtag';

const handleExternalLinkClicked = (_, data) => {
  event(data.innerText, {
    // eslint-disable-next-line id-match
    event_category: 'outbound click',
    // eslint-disable-next-line id-match
    event_label: data.href,
    linkText: data.innerText,
    linkUrl: data.href,
    // eslint-disable-next-line id-match
    non_interaction: false
  }, 'e_outboundclick');
};

export const pageExternalLinkHandlers = {
  PAGE_EXTERNAL_LINK_CLICKED_GA: handleExternalLinkClicked
};
