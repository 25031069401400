export const trackPermutive = ({xpModuleName, campaignId, xpModuleNode, event}) => {
  if (!window.permutive || !window.permutive.track) {
    return;
  }

  const closestLink = event && event.target && event.target.closest('a');

  if (!closestLink) {
    window.permutive.track('SlotClickedIframe', {promotionClick: campaignId || xpModuleName});

    return;
  }

  const href = closestLink.href;
  const position = Array.from(xpModuleNode.querySelectorAll('a')).findIndex((linkEl) => linkEl === closestLink);
  const promotionClick = `${campaignId || xpModuleName}_${position}_${href}`;

  window.permutive.track('SlotClickedIframe', {promotionClick});
};
