/* eslint-disable complexity */
import {logger} from '@mol-fe/mol-fe-client-logger';
import {later} from '@mol-fe/mol-fe-async';
import {
  getCLS,
  getFCP,
  getFID,
  getLCP,
  getTTFB,
  onCLS,
  onFID,
  onLCP,
  onTTFB,
  onFCP,
  onINP
} from 'web-vitals';
import {trackBidmax} from './bidmax';

const delay = (ms) => new Promise((resolve) => {
  setTimeout(resolve, ms);
});

const metrics = {
  CLS: null,
  FCP: null,
  FID: null,
  INP: null,
  LCP: null,
  TTFB: null
};

const updatedMetrics = {
  CLS: null,
  FCP: null,
  FID: null,
  INP: null,
  LCP: null,
  TTFB: null
};

const STANDARD_METRICS = ['CLS', 'LCP'];

let reportedAny = false;
let reportedStandard = false;

const metricHandler = ({name, value}) => {
  updatedMetrics[name] = value;

  if (reportedStandard) {
    return;
  }

  if (!reportedAny && STANDARD_METRICS.includes(name)) {
    reportedAny = true;
    later.go('mol-fe-web-vitals-partial');
  }

  metrics[name] = value;
};

const getElementSelector = (element) => {
  const idSelector = element.id ? `#${element.id.substr(0, 15)}` : '';
  const classes = Array.from(element.classList);
  const classSelector = classes.length ? `.${classes.join('.')}` : '';

  return `${element.tagName.toLowerCase()}${idSelector}${classSelector}`.substr(0, 30);
};

let reportedFidElement = false;

const fidHandler = ({name, value, entries}) => {
  metricHandler({
    name,
    value
  });

  try {
    const element = entries && entries[0] && entries[0].target;

    if (value && value >= 100 && !reportedFidElement && element) {
      reportedFidElement = true;

      const selector = `${element.parentNode ? getElementSelector(element.parentNode) : 'null'} > ${getElementSelector(element)}`;

      trackBidmax({
        event: 'fidElement',
        fidElementSelector: selector,
        fidValue: updatedMetrics.FID
      });
    }
  } catch (error) {
    // Ignore
  }
};

let reportedInpElement = false;

const inpHandler = ({name, value, entries}) => {
  metricHandler({
    name,
    value
  });

  try {
    const element = entries && entries[0] && entries[0].target;

    if (value && value >= 150 && !reportedInpElement && element) {
      reportedInpElement = true;

      const selector = `${element.parentNode ? getElementSelector(element.parentNode) : 'null'} > ${getElementSelector(element)}`;

      trackBidmax({
        event: 'inpElement',
        inpElementSelector: selector,
        inpValue: updatedMetrics.INP
      });
    }
  } catch (error) {
    // Ignore
  }
};

let clsEntries = [];

const clsHandler = ({name, value, entries}) => {
  metricHandler({
    name,
    value
  });

  clsEntries = entries;
};

let clsSendCount = 0;
let lastRecordedCls = 0;

const getHash = () => {
  try {
    return window.location.hash || 'none';
  } catch (error) {
    return 'none';
  }
};

const unloadCLS = () => {
  if (clsSendCount >= 2) {
    return;
  }

  try {
    const clsValThreshold = 0.08;

    if (updatedMetrics.CLS && updatedMetrics.CLS > clsValThreshold && updatedMetrics.CLS > lastRecordedCls && updatedMetrics.CLS - lastRecordedCls > 0.1) {
      lastRecordedCls = updatedMetrics.CLS;

      const elementThreshold = 0.01;

      for (const entry of clsEntries.filter((ent) => ent.value >= elementThreshold && !ent.hadRecentInput)) {
        const selectors = entry.sources.slice(0, 2).map((source) => getElementSelector(source.node)).join(',');

        trackBidmax({
          clsElementSelector: selectors,
          clsElementValue: entry.value,
          clsHash: getHash(),
          clsSendCount,
          clsTotal: updatedMetrics.CLS,
          event: 'clsElement'
        });
      }

      clsSendCount++;
    }
  } catch (error) {
    // ignore
  }
};

let lcpEntries = [];

const lcpHandler = ({name, value, entries}) => {
  metricHandler({
    name,
    value
  });

  lcpEntries = entries;
};

let lcpSendCount = 0;
let lastRecordedLCP = 0;

const unloadLCP = () => {
  if (lcpSendCount >= 2) {
    return;
  }

  try {
    const lcpValThreshold = 2400;

    if (updatedMetrics.LCP && updatedMetrics.LCP > lcpValThreshold && updatedMetrics.LCP > lastRecordedLCP && updatedMetrics.LCP - lastRecordedLCP > 1000) {
      lastRecordedLCP = updatedMetrics.LCP;

      const lastEntry = lcpEntries[lcpEntries.length - 1];

      if (!lastEntry) {
        return;
      }

      const selector = lastEntry.element && getElementSelector(lastEntry.element) || lastEntry.url || 'unknown';

      trackBidmax({
        event: 'lcpElement',
        lcpElementSelector: selector,
        lcpSendCount,
        lcpValue: updatedMetrics.LCP
      });

      lcpSendCount++;
    }
  } catch (error) {
    // ignore
  }
};

const listenToMetrics = async () => {
  try {
    onCLS(clsHandler, {reportAllChanges: true});
    onFID(fidHandler, {reportAllChanges: true});
    onLCP(lcpHandler, {reportAllChanges: true});
    onINP(inpHandler, {reportAllChanges: true});
    onTTFB(metricHandler, {reportAllChanges: true});
    onFCP(metricHandler, {reportAllChanges: true});

    await later('DOM_READY');
    setInterval(unloadCLS, 10000);
    setInterval(unloadLCP, 10000);
    await delay(10000);
    reportedStandard = true;
    later.go('mol-fe-web-vitals-standard');
  } catch (error) {
    logger.error('Error initialising mol-fe-web-vitals', error);
  }
};

export const getStandard = () => metrics;

export const getUpdated = () => updatedMetrics;

export const webVitals = {
  getCLS,
  getFCP,
  getFID,
  getLCP,
  getTTFB,
  onCLS,
  onFCP,
  onFID,
  onINP,
  onLCP,
  onTTFB
};

export const getLCPEntries = () => lcpEntries;

export const getCLSEntries = () => clsEntries;

listenToMetrics();
