/* eslint-disable complexity */
import {FBIA_FORCED_REFERRER} from '../constants';
import {event} from '../gtag';

const getCommonProperties = (data) => {
  const eventCategory = 'video';
  const contentType = 'video';
  const videoPlayerType = `${data.playerType} ${data.playerId}`;
  const eventLabel = data.autoplay ? 'autoplay' : 'click to play';
  const videoTitle = data.displayName;
  const videoInternalId = data.referenceId;
  const videoExternalId = data.videoId;
  const videoAdsEnabled = data.adsEnabled ? 'true' : 'false';
  let renderPlatform;
  let referrer;
  let videoPlayerVersion;
  let dmtvEpisodeId;
  let dmtvAirDate;
  let videoModule;
  let videoChannelArticleId;
  let articleId;
  let articleTitle;

  const previewTrack = data.animatedPreviewEnabled ? 'withpreview' : 'nopreview';
  const videoType = data.initialVideo ? 'inline' : 'related';
  const pageType = data.pageMetadata.articleId ? 'article' : 'non-article';

  if (data.playerId === 'gampembedded') {
    renderPlatform = 'mobile amp';
  }

  if (data.trackingType === 'fbiaembedded') {
    // Special tracking for FBIA (Facebook Instant Articles) video events
    renderPlatform = 'mobile fbia';
    referrer = FBIA_FORCED_REFERRER;
  }

  if (data.version) {
    videoPlayerVersion = 'videoplayer_' + data.version;
  }

  if (data.trackingType === 'thirdPartyEmbedded') {
    videoModule = 'video_module^embedded^' + videoType + '^offsite';
    videoChannelArticleId = 'offsite^' + videoType;
    referrer = document.referrer;
    renderPlatform = 'Offsite';
  } else if (data.trackingType === 'image_gallery') {
    videoChannelArticleId = data.pageMetadata.channel + '^' + data.pageMetadata.articleId;
    videoModule = 'video_module^' + pageType + '^' + data.trackingType + '^' + data.pageMetadata.subChannel;
  } else if (data.playlist ||
    data.trackingType === 'commercialVideoPlayer' ||
    data.trackingType === 'editorialVideoPlayer' ||
    data.trackingType === 'autoplayInlineVideoPlayer'
  ) {
    videoChannelArticleId = data.pageMetadata.channel + '^' + data.trackingType;
    videoModule = 'video_module^' + data.trackingType + '^' + data.channelShortName + '^' + data.pageMetadata.subChannel;
  } else {
    videoChannelArticleId = data.pageMetadata.channel + '^' + (data.pageMetadata.articleId === '' ? videoType : data.pageMetadata.articleId);
    videoModule = 'video_module^' + pageType + '^' + videoType + '^' + data.pageMetadata.subChannel;
  }

  if (videoModule) {
    if (!videoModule.includes('^' + previewTrack)) {
      videoModule += '^' + previewTrack;
    }
  } else {
    videoModule = 'video_module^' + previewTrack;
  }

  if (data.trackingType === 'dmtv-promo-xp-module' && !videoModule.includes('^dmtv-promo-xp-module')) {
    videoModule += '^dmtv-promo-xp-module';

    // if (sObject.hier5) {
    //   const alphaMatch = sObject.hier5.match(/\^(alpha-\d+)\^/);
    //   const gammaMatch = sObject.hier5.match(/\^(gamma-\d+)\^/);

    //   if (alphaMatch) {
    //     sObject.eVar44 += `^${alphaMatch[1]}`;
    //   } else if (gammaMatch) {
    //     sObject.eVar44 += `^${gammaMatch[1]}`;
    //   }
    // }
  }

  if (data.pageMetadata.articleId && (data.trackingType === 'embedded' || videoModule.match(/\^article\^(inline|image_gallery)\^/))) {
    articleId = data.pageMetadata.articleId;
    articleTitle = data.pageMetadata.articleTitle;
  } else {
    articleId = 'video not embedded within the article';
    articleTitle = 'video not embedded within the article';
  }

  if (data.dmtvEpisode) {
    const {
      number,
      airDate
    } = data.dmtvEpisode;

    dmtvEpisodeId = number;
    dmtvAirDate = airDate;
  }

  return {
    articleId,
    articleTitle,
    contentType,
    dmtvAirDate,
    dmtvEpisodeId,
    // eslint-disable-next-line id-match
    event_category: eventCategory,
    // eslint-disable-next-line id-match
    event_label: eventLabel,
    // eslint-disable-next-line id-match
    non_interaction: true,
    playMethod: eventLabel,
    referrer,
    renderPlatform,
    videoAdsEnabled,
    videoChannelArticleId,
    videoExternalId,
    videoInternalId,
    videoModule,
    videoPlayerType,
    videoPlayerVersion,
    videoTitle
  };
};

const handleVideoAdvertStartedEvent = function (eventName, data) {
  const props = getCommonProperties(data);

  event('advert start', {
    ...props,
    videoAdvertStartIncrease: 1
  });
};

const handleVideoAdvertCompletedEvent = function (eventName, data) {
  const props = getCommonProperties(data);

  event('advert complete', props);
};

const handleVideoAdvertAbortedEvent = function (eventName, data) {
  const props = getCommonProperties(data);

  event('advert aborted', props);
};

const handleVideoContentStartedEvent = function (eventName, data) {
  const props = getCommonProperties(data);

  event('content start', props);
};

const handleVideoContentCompletedEvent = function (eventName, data) {
  const props = getCommonProperties(data);

  event('complete', {
    ...props,
    videoCompleteIncrease: 1
  });
};

const handleVideoContentAbortedEvent = function (eventName, data) {
  const props = getCommonProperties(data);

  event('aborted', props);
};

const handleVideoStreamStartedEvent = function (eventName, data) {
  const eventAction = data.continuePlayList ? 'stream continuous play' : 'stream started';
  const props = getCommonProperties(data);

  event(eventAction, {
    ...props,
    // eslint-disable-next-line id-match
    non_interaction: !data.autoplay,
    videoViewIncrease: 1
  });
};

const ACTION_BY_SEGMENT = {
  'M:25-50': '25%',
  'M:50-100': '50%'
};
const METRIC_BY_SEGMENT = {
  'M:25-50': 'video25Increase',
  'M:50-100': 'video50Increase'
};
const VALID_SEGMENTS = ['M:25-50', 'M:50-100'];

const handleVideoProgressEvent = function (eventName, data) {
  if (!data.segment || !VALID_SEGMENTS.includes(data.segment)) {
    return;
  }

  const eventAction = ACTION_BY_SEGMENT[data.segment];
  const props = getCommonProperties(data);
  const metricKey = METRIC_BY_SEGMENT[data.segment];

  event(eventAction, {
    ...props,
    [metricKey]: 1
  });
};

// Reason for the code below in MOL-17461 / MOL-20170
const excludeChromeless = (handlers) => {
  const newHandlers = {};

  for (const eventKey of Object.keys(handlers)) {
    newHandlers[eventKey] = (eventName, data) => {
      if (typeof data.trackingType === 'string' && data.trackingType.includes('chromeless')) {
        return;
      }

      handlers[eventKey](eventName, data);
    };
  }

  return newHandlers;
};

export const videoHandlers = excludeChromeless({
  VIDEO_ADVERT_ABORTED: handleVideoAdvertAbortedEvent,
  VIDEO_ADVERT_COMPLETED: handleVideoAdvertCompletedEvent,
  VIDEO_ADVERT_STARTED: handleVideoAdvertStartedEvent,
  VIDEO_CONTENT_ABORTED: handleVideoContentAbortedEvent,
  VIDEO_CONTENT_COMPLETED: handleVideoContentCompletedEvent,
  VIDEO_CONTENT_STARTED: handleVideoContentStartedEvent,
  VIDEO_PROGRESS: handleVideoProgressEvent,
  VIDEO_STREAM_STARTED: handleVideoStreamStartedEvent
});
