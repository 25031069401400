import {getPageMetadata} from '@mol-fe/mol-fe-page-metadata';
import {PACKAGE_NAME} from '../../../../../config';
import createXpModuleNodeListFromString from './utils/createXpModuleNodeListFromString';
import disableXpModuleNode from './utils/disableXpModuleNode';

export const FILENAME = 'addXpModuleBodyToElement';

const addXpModuleBodyToElement = (body, element) => {
  if (typeof body !== 'string' || body === '') {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.invalidProps`,
      {
        body,
      }
    );

    return null;
  }

  if (!(element instanceof HTMLElement)) {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.invalidProps`,
      {
        element,
      }
    );

    return null;
  }

  try {
    const wrapper = document.createElement('div');

    wrapper.className = 'mol-fe-ads-promotional-slot';

    const {renderPlatform} = getPageMetadata();

    if (renderPlatform === 'mobile') {
      wrapper.style.boxSizing = 'border-box';
      wrapper.style.paddingLeft = '10px';
      wrapper.style.paddingRight = '10px';
    }

    disableXpModuleNode(wrapper);

    const nodeList = createXpModuleNodeListFromString(body);

    if (!nodeList || nodeList.length === 0) {
      console.error(
        `[${PACKAGE_NAME}] ${FILENAME}.createXpModuleNodeListFromString`, {
          body,
          nodeList,
        }
      );

      return null;
    }

    wrapper.append(...nodeList);

    element.after(wrapper);

    return wrapper;
  } catch (error) {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.thrownError`,
      {
        body,
        element,
        error,
      }
    );

    return null;
  }
};

export default addXpModuleBodyToElement;
