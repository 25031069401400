import {PACKAGE_NAME} from '../../../../../../config';

export const FILENAME = 'arePropsValid';

const arePropsValid = ({scripts, styles}) => {
  if (!Array.isArray(scripts)) {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.invalidProps`,
      {
        scripts,
      }
    );

    return false;
  }

  if (!Array.isArray(styles)) {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.invalidProps`,
      {
        styles,
      }
    );

    return false;
  }

  return true;
};

export default arePropsValid;
