import {logger} from '@mol-fe/mol-fe-client-logger';
import pageEvents from '../pageEvents';

const SELECTOR_TO_FURNITURE_NAME = {
  '.mol-desktop #addCommentButton, .mol-desktop #addCommentNarrowButton': 'add comment bottom',
  '.mol-desktop #articleIconLinksContainer .comments-count': 'comments top',
  '.mol-desktop #floating-share-bar-container .comments-count': 'comments floating'
};

export const initFurnitureTracking = () => {
  try {
    document.addEventListener('click', (event) => {
      const key = Object.keys(SELECTOR_TO_FURNITURE_NAME).find((selector) => event.target.closest(selector));

      if (!key) {
        return;
      }

      const furnitureName = SELECTOR_TO_FURNITURE_NAME[key];

      pageEvents.publish(pageEvents.PAGE_FURNITURE_CLICKED, {furnitureName});
    });
  } catch (error) {
    logger.error('Error initialising furniture tracking', error);
  }
};
