import {event} from '../gtag';

const createHandler = (category) => (_, data) => {
  event(data.hrefTxt, {
    // eslint-disable-next-line id-match
    event_category: category,
    // eslint-disable-next-line id-match
    event_label: data.hrefUrl,
    linkText: data.hrefTxt,
    linkUrl: data.hrefUrl,
    // eslint-disable-next-line id-match
    non_interaction: false
  }, 'e_affiliateclick');
};

export const affiliateCarouselHandlers = {
  AFFILIATE_CAROUSEL_CLICK: createHandler('affiliate click')
};
