import {getPageCriteria} from '@mol-fe/mol-fe-page-metadata';
import {logger} from '@mol-fe/mol-fe-client-logger';
import {addArticleRead} from './articles';
import {addChannelCount} from './channels';

export const init = () => {
  try {
    const {articleId, channel, pageType} = getPageCriteria();

    if (pageType !== 'article') {
      return;
    }

    addArticleRead(Number(articleId));
    addChannelCount(channel);
  } catch (error) {
    logger.error('Error initialising mol-fe-article-read', error);
  }
};
