import {PACKAGE_NAME} from '../../../../../../config';

export const FILENAME = 'createScriptFromUrl';

const createScriptFromUrl = (url, onLoad, onError) => {
  if (typeof url !== 'string') {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.invalidProps`, {
        url,
      }
    );

    return null;
  }

  if (typeof onLoad !== 'function') {
    console.error(`[${PACKAGE_NAME}] ${FILENAME}.invalidProps`, {
      onLoad,
    });

    return null;
  }

  if (typeof onError !== 'function') {
    console.error(`[${PACKAGE_NAME}] ${FILENAME}.invalidProps`, {
      onError,
    });

    return null;
  }

  const script = document.createElement('script');

  script.type = 'text/javascript';
  script.src = url;
  script.onload = onLoad;
  script.onerror = onError;

  return script;
};

export default createScriptFromUrl;
