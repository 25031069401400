import jsCookie from 'js-cookie';

export const AUTH_STATUS = {
  FB_LOGGED_IN: 'FB Logged-In User',
  FB_LOGGED_OUT: 'FB Logged-out User',
  FB_REGISTERING: 'FB Registering User',
  GOOGLE_LOGGED_IN: 'Google Logged-In User',
  GOOGLE_LOGGED_OUT: 'Google Logged-out User',
  GOOGLE_REGISTERING: 'Google Registering User',

  // if the user never registered/logged in
  GUEST: 'Guest',

  // if the user directly registered (i.e. not from social) and is still logged in
  SITE_LOGGED_IN: 'Site Logged-In User',

  // if the user directly registered (i.e. not from social) and is not logged in
  SITE_LOGGED_OUT: 'Site Logged-out User',

  // if the user is directly registering (i.e. not from social)
  SITE_REGISTERING: 'Site Registering User',
  TWITTER_LOGGED_IN: 'Twitter Logged-In User',
  TWITTER_LOGGED_OUT: 'Twitter Logged-out User',
  TWITTER_REGISTERING: 'Twitter Registering User'
};

const getRegSource = function () {
  return jsCookie.get('_rs') || '';
};

const getAuthStatus = function () {
  return jsCookie.get('_as') || AUTH_STATUS.GUEST;
};

export const getRegistrationMethod = function () {
  switch (getAuthStatus()) {
  case AUTH_STATUS.FB_LOGGED_IN:
  case AUTH_STATUS.FB_LOGGED_OUT:
  case AUTH_STATUS.FB_REGISTERING:
    return 'facebook';
  case AUTH_STATUS.GOOGLE_LOGGED_IN:
  case AUTH_STATUS.GOOGLE_LOGGED_OUT:
  case AUTH_STATUS.GOOGLE_REGISTERING:
    return 'google';
  case AUTH_STATUS.TWITTER_LOGGED_IN:
  case AUTH_STATUS.TWITTER_LOGGED_OUT:
  case AUTH_STATUS.TWITTER_REGISTERING:
    return 'twitter';
  default:
    return 'default';
  }
};

export const getUserDetails = () => `${getAuthStatus()}|${getRegSource()}`;

