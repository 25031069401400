// global polyfills
/* eslint-disable import/no-unassigned-import */
import '@mol-fe/mol-fe-page-header';
import '@mol-fe/mol-fe-ads';
import '@mol-fe/mol-fe-footer';
import '@mol-fe/mol-fe-infinite-scroll';
import '@mol-fe/mol-fe-author-pages';
import '@mol-fe/mol-fe-breaking-news';
import '@mol-fe/mol-fe-puff';
import '@mol-fe/mol-fe-sync-article-styles';
import '@mol-fe/mol-fe-sync-article-styles/src/desktop';
import '@mol-fe/mol-fe-hide-facebook';
import '@mol-fe/mol-fe-came-from-mol';
import '@mol-fe/mol-fe-auto-puff';
import '@mol-fe/mol-fe-related-replace';
import './lib/scrollToTop';

import {initTracking as initArticlePreviewTracking} from '@mol-fe/mol-fe-channel-article-preview-icons';
import * as molFeMiniLodash from '@mol-fe/mol-fe-mini-lodash';
import * as molFeMobileutils from '@mol-fe/mol-fe-mobileutils';
import {setExperimentsToAdsMVT} from '@mol-fe/mol-fe-multivariant/setAdsMvt';
import '@mol-fe/mol-fe-web-fonts';
import {isAdFreeEntitled} from './isAdFreeEntitled';
import {mastheadLinkHot} from './mastheadLinkHot';
import * as DM from './DM';

window.DM = DM;
window._ = molFeMiniLodash;
window.MobileUtils = molFeMobileutils;
window.isAdFreeEntitled = isAdFreeEntitled;
Object.freeze(window.DM.pageEvents);

initArticlePreviewTracking();
mastheadLinkHot();
setExperimentsToAdsMVT();

export * from './DM';
