/**
 * The reason for using setTimeout instead of setInterval
 * is to not re-run revalidate more often than the body
 * of the function can be evaluated.
 */
const createIntervalIterator = function (callback, interval) {
  let currentTimeout;
  let lastExecutionTime = Date.now();

  const iterator = () => {
    // eslint-disable-next-line callback-return, promise/prefer-await-to-callbacks
    callback();

    // negative timeout values are OK
    const deltaLastExecution = Date.now() - lastExecutionTime;
    const deltaNextExecution = interval - deltaLastExecution;

    lastExecutionTime = Date.now();
    currentTimeout = setTimeout(iterator, deltaNextExecution);
  };

  iterator();

  return () => clearTimeout(currentTimeout);
};

export default createIntervalIterator;
