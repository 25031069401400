import {getFromStorage, setToStorage} from './storage';

const STORAGE_KEY_ARTICLES_SEEN = 'articles-seen';
const STORAGE_KEY_ARTICLES_READ = 'articles-read';
const MAX_ARTICLES_IN_LIST = 500;

const addArticleToStorageList = (articleId, storageKey) => {
  const articlesInList = getFromStorage(storageKey) || [];

  if (articlesInList.find(([id]) => id === articleId)) {
    return;
  }

  let newArticlesInList = [
    ...articlesInList,
    [articleId, Math.floor(Date.now() / 1000)]
  ];

  if (newArticlesInList.length > MAX_ARTICLES_IN_LIST) {
    newArticlesInList = newArticlesInList.slice(newArticlesInList.length - MAX_ARTICLES_IN_LIST);
  }

  setToStorage(storageKey, newArticlesInList);
};

export const addArticleSeen = (articleId) => addArticleToStorageList(articleId, STORAGE_KEY_ARTICLES_SEEN);

export const addArticleRead = (articleId) => addArticleToStorageList(articleId, STORAGE_KEY_ARTICLES_READ);

export const isArticleInRead = (articleId) => Boolean((getFromStorage(STORAGE_KEY_ARTICLES_READ) || []).find(([id]) => id === articleId));

export const isArticleInSeen = (articleId) => Boolean((getFromStorage(STORAGE_KEY_ARTICLES_SEEN) || []).find(([id]) => id === articleId));

const EXPIRE_READ = 10 * 60 * 1000;
const EXPIRE_SEEN = 20 * 60 * 1000;

export const isArticleExpired = (articleId) => {
  const now = Date.now();
  const read = (getFromStorage(STORAGE_KEY_ARTICLES_READ) || []).find(([id]) => id === articleId);

  if (read && now - read[1] * 1000 > EXPIRE_READ) {
    return true;
  }

  const seen = (getFromStorage(STORAGE_KEY_ARTICLES_SEEN) || []).find(([id]) => id === articleId);

  if (seen && now - seen[1] * 1000 > EXPIRE_SEEN) {
    return true;
  }

  return false;
};
