import {PACKAGE_NAME} from '../../../../config';

export const FILENAME = 'validateProps';

const validateProps = (elementSelector, xpModule) => {
  if (typeof elementSelector !== 'string') {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.invalidProps`,
      {
        elementSelector,
      }
    );

    return null;
  }

  if (typeof xpModule !== 'object' || typeof xpModule.name !== 'string' || typeof xpModule.values !== 'object') {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.invalidProps`,
      {
        xpModule,
      }
    );

    return null;
  }

  return true;
};

export default validateProps;
