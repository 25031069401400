import {event} from '../gtag';

const createHandler = (category, action, nonInteraction) => (_, data) => {
  const {label, value} = data || {};

  event(action, {
    // eslint-disable-next-line id-match
    event_category: category,
    // eslint-disable-next-line id-match
    event_label: label,
    // eslint-disable-next-line id-match
    non_interaction: Boolean(nonInteraction),
    value
  });
};

export const commerceXpHandlers = {
  COMMERCE_XP_CLICK: createHandler('commerce xp', 'click'),
  COMMERCE_XP_IMPRESSION: createHandler('commerce xp', 'impression', true),
  COMMERCE_XP_VIEW_MORE: createHandler('commerce xp', 'view more'),
  COMMERCE_XP_VIEWABLE: createHandler('commerce xp', 'viewable', true)
};
