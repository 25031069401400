import {later} from '@mol-fe/mol-fe-async';
import {logger} from '@mol-fe/mol-fe-client-logger';
// eslint-disable-next-line import/no-unresolved
import './styles/index.scss';

let hideHoverableMenuTimeout;
let currentTarget;

later('DOM_READY', () => {
  const pageHeader = document.querySelector('div.page-header');

  if (window.DM.isLoggedIn) {
    const logoutComps = pageHeader.querySelectorAll('ul[data-logout-comp]');
    const loginComps = pageHeader.querySelectorAll('ul[data-login-comp]');

    logoutComps.forEach((logoutComp) => {
      logoutComp.style.display = 'block';
    });

    loginComps.forEach((loginComp) => {
      loginComp.style.display = 'none';
    });
  }

  const primaryNav = pageHeader.querySelector('ul.nav-primary');

  if (!primaryNav) {
    return;
  }

  const hoverableMenus = primaryNav.querySelectorAll('li[data-has-menu-on-hover]');

  // TODO: As requested by PM (because this feature had to be released really really quickly to prod and chances are that in the near future there will be a vertical hoverable menus instead of an horizontal ones), this feature ONLY works when there is ONLY ONE hoverable menu. Whenever new hoverable menus will be added, we have to set a way of record which one is currentTarget for every one of them depending on the chosen logic, because the behavior logic IS NOT SET at this moment. We don't know at this point what will be happening exactly when, for example, the user hovers over a new hoverable menu when the old one is still being shown (with the delay). Should we show the new one? should we wait for the delay to finish first? Even when the logic would suggest that showing the new hoverable menu, that will break the reason why we added a delay in the first place.

  const menuHover = (ev) => {
    currentTarget = ev.currentTarget;
    clearTimeout(hideHoverableMenuTimeout);

    if (currentTarget) {
      const secondary = currentTarget.querySelector('.nav-secondary-container');

      if (secondary) {
        secondary.style.display = 'block';
      } else {
        logger.error('No secondary nav container on menuHover for currentTarget: ', currentTarget);
      }
    } else {
      logger.error('No currentTarget on menuHover with event: ', ev);
    }
  };

  const menuUnHover = (ev) => {
    currentTarget = ev.currentTarget;
    hideHoverableMenuTimeout = setTimeout(() => {
      if (currentTarget) {
        const secondary = currentTarget.querySelector('.nav-secondary-container');

        if (secondary) {
          secondary.style.display = 'none';
        } else {
          logger.error('No secondary nav container on menuUnHover for currentTarget: ', currentTarget);
        }
        currentTarget = null;
      } else {
        logger.error('No currentTarget on delayed menuUnHover');
      }
    }, 500);
  };

  hoverableMenus.forEach((hoverableMenu) => {
    hoverableMenu.onmouseover = menuHover;
    hoverableMenu.onmouseout = menuUnHover;

    hoverableMenu.addEventListener('touchstart', menuHover, false);
    hoverableMenu.addEventListener('touchend', menuUnHover, false);
  });
});
