import {PACKAGE_NAME} from '../../../config';
import onXpModuleIsVisibleForTheFirstTime from './utils/onXpModuleIsVisibleForTheFirstTime/onXpModuleIsVisibleForTheFirstTime';

export const OBSERVER_OPTIONS = {
  threshold: 1.0
};
export const FILENAME = 'addViewableListenerToXpModuleNode';

const addViewableListenerToXpModuleNode = (xpModuleNode, xpModuleName, campaignId) => {
  if (!(xpModuleNode instanceof HTMLDivElement)) {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.invalidProps`,
      {
        xpModuleNode,
      }
    );

    return null;
  }

  if (typeof xpModuleName !== 'string') {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.invalidProps`,
      {
        xpModuleName,
      }
    );

    return null;
  }

  try {
    const observer = new IntersectionObserver((entries, obs) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          const element = entry.target;

          onXpModuleIsVisibleForTheFirstTime(xpModuleName, campaignId);

          // send the message only once
          obs.unobserve(element);
        }
      }
    }, OBSERVER_OPTIONS);

    observer.observe(xpModuleNode);

    return xpModuleNode;
  } catch (error) {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.thrownError`,
      {
        error,
        xpModuleName,
        xpModuleNode,
      }
    );

    return null;
  }
};

export default addViewableListenerToXpModuleNode;
