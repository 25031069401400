import {later} from '@mol-fe/mol-fe-async';
import {setConfig} from '@mol-fe/mol-fe-components';
import loadHandlers from '../utils/loadHandlers';
import {gtag, loadGtag} from './gtag';
import {handlers} from './handlers';
import {listenToPageExternalClicks} from './pageExternalClicks';
import {getConfigProps} from './meta';

const isAllowedBrowser = !Boolean(navigator.userAgent.match(/snapchat/i));

if (isAllowedBrowser) {
  loadGtag().catch();

  for (const eventHandlers of handlers) {
    loadHandlers('googleAnalytics', eventHandlers);
  }

  listenToPageExternalClicks();
}

export const googleAnalytics = {gtag};

later('PAGE_METADATA_SET', () => {
  setConfig('GA_CONFIG_PROPS', getConfigProps());
  later.go('GA_CONFIG_READY');
});
