import {name} from '../../package.json';

export const PACKAGE_NAME = name;

const PROD_HOSTNAME = 'www.dailymail.co.uk';
const INT_HOSTNAME = 'www.dailymailint.co.uk';

export const hostname = window.location.hostname === PROD_HOSTNAME ?
  PROD_HOSTNAME :
  INT_HOSTNAME;

export const SUPPORTED_EVENTS = ['impression', 'click', 'viewable'];

export const TIMEOUT_IN_MILLISECONDS = 10000;

