import {PACKAGE_NAME} from '../../../../../config';

export const FILENAME = 'enableXpModuleNode';

const enableXpModuleNode = (xpModuleNode) => {
  if (!(xpModuleNode instanceof HTMLDivElement)) {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.invalidProps`,
      {
        xpModuleNode,
      }
    );

    return null;
  }

  // revert disableXpModuleNode styles
  xpModuleNode.style.opacity = '1';
  xpModuleNode.style.pointerEvents = 'initial';
  xpModuleNode.style.cursor = 'auto';

  return xpModuleNode;
};

export default enableXpModuleNode;
