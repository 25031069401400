import {pageEvents} from '@mol-fe/mol-fe-tracking';
import {PACKAGE_NAME, SUPPORTED_EVENTS} from '../../../config';
import postBidmaxTrackingMessage from './utils/postBidmaxTrackingMessage/postBidmaxTrackingMessage';

export const FILENAME = 'publishTrackingEvent';

const publishTrackingEvent = ({eventName, xpModuleName, campaignId}) => {
  if (!SUPPORTED_EVENTS.includes(eventName)) {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.invalidProps`,
      {
        eventName,
      }
    );

    return null;
  }

  if (typeof xpModuleName !== 'string') {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.invalidProps`,
      {
        xpModuleName,
      }
    );

    return null;
  }

  try {
    pageEvents.publish(
      pageEvents.PROMOTIONAL_SLOT_EVENT,
      {
        campaignId,
        eventName,
        xpModuleName,
      }
    );

    postBidmaxTrackingMessage({
      campaignId,
      eventName,
      xpModuleName,
    });

    return true;
  } catch (error) {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.thrownError`,
      {
        error,
        eventName,
        xpModuleName,
      }
    );

    return null;
  }
};

export default publishTrackingEvent;
