import jsCookie from 'js-cookie';
import {event} from '../gtag';

const createHandler = (category, action) => () => {
  event(action, {
    // eslint-disable-next-line id-match
    event_category: category,
    // eslint-disable-next-line id-match
    non_interaction: true
  });
};

const handleMobileHomePageViewEvent = (_, data) => {
  const currentView = data.swipe || jsCookie.get('mol-home');
  const currentGeo = jsCookie.get('mol-home-default-channel');

  let chosenOverlay = 'Mobile Landing Homepage';

  switch (currentView) {
  case 'news':
    chosenOverlay = 'Just the News';
    break;
  case 'showbiz':
    chosenOverlay = 'Just the Showbiz';
    break;
  case 'desktop':
    chosenOverlay = 'Full Homepage';

    if (currentGeo) {
      chosenOverlay += ` ${currentGeo}-CA`;
    }
    break;
  }

  if (data.actionSource) {
    chosenOverlay += ` ${data.actionSource}`;
  }

  event('choice', {
    // eslint-disable-next-line id-match
    event_category: 'mobile homepage overlay',
    // eslint-disable-next-line id-match
    event_label: chosenOverlay,
    mobileHomePreference: chosenOverlay,
    // eslint-disable-next-line id-match
    non_interaction: false,
    selectedValue: chosenOverlay
  });
};

const handleMobileHomePageSwipeEvent = (_, data) => {
  const {action, label} = data;

  event('choice', {
    // eslint-disable-next-line id-match
    event_action: action,
    // eslint-disable-next-line id-match
    event_category: 'mobile homepage nav',
    // eslint-disable-next-line id-match
    event_label: label,
    // eslint-disable-next-line id-match
    non_interaction: false,
    selectedValue: label
  });
};

const handleMobileHomePageNavEvent = (_, data) => {
  const {action, label} = data;

  event(action, {
    // eslint-disable-next-line id-match
    event_category: 'mobile homepage nav',
    // eslint-disable-next-line id-match
    event_label: label,
    // eslint-disable-next-line id-match
    non_interaction: false,
    selectedValue: label
  });
};

export const pageOverlayHandlers = {
  DESKTOP_HOME_PAGE_OVERLAY: createHandler('desktop homepage overlay', 'display'),
  MOBILE_HOME_PAGE_NAV: handleMobileHomePageNavEvent,
  MOBILE_HOME_PAGE_OVERLAY: createHandler('mobile homepage overlay', 'display'),
  MOBILE_HOME_PAGE_SWIPE: handleMobileHomePageSwipeEvent,
  MOBILE_HOME_PAGE_VIEW: handleMobileHomePageViewEvent
};
