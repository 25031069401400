/* eslint-disable complexity */
import {later} from '@mol-fe/mol-fe-async';
import {getPageCriteria, getPageMetadata} from '@mol-fe/mol-fe-page-metadata';
import {pageEvents} from '@mol-fe/mol-fe-tracking';

const MAIL_PLUS_MASTHEAD_ENABLED = true;
const MAIL_PLUS_MASTHEAD_US_ENABLED = true;

export const mastheadLinkHot = async () => {
  try {
    await later('PAGE_METADATA_SET');

    const {pageType, channel, subchannel, geo} = getPageCriteria();
    const {environment} = getPageMetadata();

    if (pageType !== 'channel' || channel !== subchannel || !['home', 'auhome', 'ushome', 'ussport'].includes(channel)) {
      return;
    }

    if (channel !== 'home') {
      return;
    }

    const masthead = document.querySelector('.page-header .masthead');

    if (!masthead) {
      return;
    }

    if (channel === 'home' && !['GB', 'US'].includes(geo)) {
      return;
    }

    let href = 'https://pubads.g.doubleclick.net/gampad/clk?id=5940070068&iu=/5765';
    let bgImage = 'https://i.dailymail.co.uk/i/pix/channelheaders/UKRAINE_MAS.png';
    let trackingText = 'ukraine appeal masthead';
    let linkWidth = '400px';
    let trackName = 'ukraine';
    let linkRel = null;
    let backgroundSize = null;
    const linkTarget = '_blank';

    if (geo === 'US') {
      href = 'https://donations.cafamerica.org/mail-force/';
    }

    if (MAIL_PLUS_MASTHEAD_ENABLED && ['GB', 'US'].includes(geo) && channel === 'home') {
      const mastheadBasePath = `https://i.dailymail.co.uk/static/mol-fe/static/configs/mol-fe-mailplus-masthead${environment === 'integration' ? '-int' : ''}/`;

      if (geo !== 'US' || MAIL_PLUS_MASTHEAD_US_ENABLED) {
        href = 'https://www.mailplus.co.uk/newspaper?utm_source=mailonline&utm_medium=banner&utm_campaign=homepage';
        bgImage = `${mastheadBasePath}/mailplus_masthead_uk.png`;
        trackingText = 'mailplus masthead';
        linkWidth = '430px';
        trackName = 'mailplus';
        linkRel = 'nofollow noopener';
        backgroundSize = '500px 91px';

        if (geo === 'US') {
          href = 'https://www.mailplus.co.uk/newspaper?utm_source=mailonline&utm_medium=banner&utm_campaign=homepage_us';
          bgImage = `${mastheadBasePath}/mailplus_masthead_us.png`;
          trackingText = 'mailplus us masthead';
          trackName = 'mailplus_us';
          backgroundSize = '496px 91px';
        }
      }
    }

    if (href) {
      const link = document.createElement('a');

      link.href = href;

      if (linkTarget) {
        link.target = linkTarget;
      }

      link.style.position = 'absolute';
      link.style.zIndex = 10;
      link.style.top = '0px';
      link.style.right = '0px';
      link.style.height = '91px';
      link.style.width = linkWidth;

      if (trackingText) {
        link.dataset.trackingInnerText = trackingText;
      }

      if (linkRel) {
        link.rel = linkRel;
      }

      if (trackName) {
        link.addEventListener('click', () => {
          pageEvents.publish(pageEvents.MASTHEAD_CLICK, {mastheadName: trackName});
        });
      }

      masthead.appendChild(link);
    }

    masthead.style.backgroundImage = `url(${bgImage})`;

    if (backgroundSize) {
      masthead.style.backgroundSize = backgroundSize;
    }
  } catch (error) {
    // ignore
  }
};
