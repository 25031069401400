import onPromotionalSlotMessage from './onPromotionalSlotMessage/onPromotionalSlotMessage';

const onMessageReceived = (event) => {
  if (!event.data || !event.data.scope) {
    return;
  }

  const {
    data,
    scope
  } = event.data;

  if (scope === 'mol-fe-promotional-slot') {
    onPromotionalSlotMessage(data);
  }
};

export default onMessageReceived;
