import {PACKAGE_NAME} from '../../../../../config';
import getAllScriptsInPage from './utils/getAllScriptsInPage';
import getAllStylesInPage from './utils/getAllStylesInPage';
import arePropsValid from './utils/arePropsValid';

export const FILENAME = 'canTheXpModuleBeAdded';

// Promotional Slot module is designed to have only ONE instance of the xpModule in the page.
// If it has already been added to the page, we don't add it again.
const canTheXpModuleBeAdded = ({
  scripts,
  styles
}) => {
  if (!arePropsValid({
    scripts,
    styles,
  })) {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.invalidProps`,
      {
        scripts,
        styles
      }
    );

    return false;
  }

  const stylesInPage = getAllStylesInPage();

  for (const styleUrl of styles) {
    if (stylesInPage[styleUrl]) {
      console.error(
        `[${PACKAGE_NAME}] ${FILENAME}.styleAlreadyInDom`,
        '\nPromotional Slot module is designed to have only ONE instance of a given xpModule in the page.',
        '\nIf the new xpModule instance has already been added to the page, it won\'t be added to the DOM again.',
        {
          styleUrl,
        }
      );

      return false;
    }
  }

  const scriptsInPage = getAllScriptsInPage();

  for (const scriptSrc of scripts) {
    if (scriptsInPage[scriptSrc]) {
      console.error(
        `[${PACKAGE_NAME}] ${FILENAME}.scriptAlreadyInDom`,
        '\nPromotional Slot module is designed to have only ONE instance of a given xpModule in the page.',
        '\nIf the new xpModule instance has already been added to the page, it won\'t be added to the DOM again.',
        {
          scriptSrc,
        }
      );

      return false;
    }
  }

  return true;
};

export default canTheXpModuleBeAdded;
