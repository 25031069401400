import {event} from '../gtag';

const createHandler = (action) => (_, data) => {
  const params = {
    // eslint-disable-next-line id-match
    event_category: data.contentType,
    galleryImageAction: data.galleryTitle,
    galleryImageId: data.threadId,
    galleryImageSwipeCount: data.galleryId,
    // eslint-disable-next-line id-match
    non_interaction: false
  };

  if (data.contentType === 'image') {
    params.imageViewIncrease = 1;
  }

  event(action, params, `e_gallery${data.contentType}${action}`);
};

export const galleryHandlers = {
  GALLERY_IMAGE_VIEWED: createHandler('view'),
  GALLERY_OPENED: createHandler('start')
};
