import {PACKAGE_NAME} from '../../../../../../config';

export const FILENAME = 'createXpModuleNodeListFromString';

const createXpModuleNodeListFromString = (htmlString) => {
  if (typeof htmlString !== 'string') {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.invalidProps`,
      {
        htmlString,
      }
    );

    return null;
  }

  try {
    const div = document.createElement('div');

    div.innerHTML = htmlString.trim();

    return div.childNodes;
  } catch (error) {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.thrownError`,
      {
        error,
        htmlString,
      }
    );

    return null;
  }
};

export default createXpModuleNodeListFromString;
