const getAllScriptsInPage = () => {
  const allScriptsInPage = {};

  document.querySelectorAll('script')
    .forEach(({src}) => {
      if (src !== '') {
        allScriptsInPage[src] = true;
      }
    });

  return allScriptsInPage;
};

export default getAllScriptsInPage;
