// https://developer.mozilla.org/en-US/docs/Web/API/ParentNode/prepend
import createDocumentFragment from '../utils/createDocumentFragment';

const prepend = () => {
  const prototypes = [Element.prototype, CharacterData.prototype, DocumentType.prototype];

  prototypes.forEach((prototype) => {
    if (typeof prototype.prepend !== 'function') {
      prototype.prepend = function (...elements) {
        const documentFragment = createDocumentFragment(elements);

        this.insertBefore(documentFragment, this.firstChild);
      };
    }
  });
};

export default prepend;
