import axios from 'axios';
import {getPageCriteria, getPageMetadata} from '@mol-fe/mol-fe-page-metadata';
import {isArticleInRead} from '@mol-fe/mol-fe-article-read';

const API_BASE_URL = '/api/mol-fe-feeds/v2/articles/rankedByChannelReferrer';

const API_SUFFIX_BY_GEO = {
  AU: '/auhome',
  GB: '/home',
  US: '/ushome'
};

const MAX_ARTICLES = 50;

export const getArticles = async () => {
  const {articleId, geo} = getPageCriteria();
  const {domain, environment} = getPageMetadata();
  const suffix = API_SUFFIX_BY_GEO[geo] || API_SUFFIX_BY_GEO.GB;
  const prefixIfTim = domain === 'thisismoney' ? `https://www.dailymail${environment === 'production' ? '' : 'int'}.co.uk` : '';
  const url = `${prefixIfTim}${API_BASE_URL}${suffix}`;

  const response = await axios.get(url);

  if (!response || !response.data || !response.data.articles) {
    throw new Error('Invalid ranked articles response');
  }

  const {articles} = response.data;

  return articles
    .filter((article) => !isArticleInRead(article.articleId) && article.articleId !== articleId)
    .slice(0, MAX_ARTICLES);
};
