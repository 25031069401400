const getStatus = () => {
  try {
    return JSON.parse(window.localStorage.getItem('mol-fe-paywall-status'));
  } catch (error) {
    // Ignore
  }

  return null;
};

const getDeviceId = () => {
  const status = getStatus();
  const userId = window.DM && window.DM.userId;

  if (!userId && !status) {
    return null;
  }

  const registeredUserId = userId || status && status.registeredUserId;

  try {
    return JSON.parse(window.localStorage.getItem(`mol-fe-paywall-deviceId-${registeredUserId}`));
  } catch (error) {
    // Ignore
  }

  return null;
};

export const getAdFreeDimensions = () => {
  const dimensions = {};

  try {
    if (window.isAdFreeEntitled) {
      const isAdFreeEntitled = window.isAdFreeEntitled();

      dimensions.adFreeVersionStatus = isAdFreeEntitled ? 'Ad Free' : 'Ads Shown';
    }

    const deviceId = getDeviceId();

    if (deviceId) {
      dimensions.adFreeUserStatus = 'Adfree Subscriber';
      dimensions.adFreeDeviceNumber = deviceId;
    }
  } catch (error) {
    // Ignore
  }

  return dimensions;
};
