/* eslint-disable complexity */
import {getPageCriteria, getPageMetadata} from '@mol-fe/mol-fe-page-metadata';
import {later} from '@mol-fe/mol-fe-async';
import {logger} from '@mol-fe/mol-fe-client-logger';
import {getArticles} from './api';
import {placeArticlesInPuffs} from './puff';

const ENABLED_CHANNELS = ['news', 'property', 'health', 'sciencetech', 'travel'];
const ENABLED_SUBCHANNELS = ['property'];

export const init = async () => {
  try {
    const {articleStyle, channel, pageType, subchannel} = getPageCriteria();
    const {renderPlatform, domain} = getPageMetadata();

    if (pageType !== 'article' || renderPlatform !== 'desktop' || articleStyle !== 'wide') {
      return;
    }

    if (domain === 'thisismoney') {
      if (subchannel !== 'moneycars') {
        return;
      }

      if (!document.referrer || !document.referrer.match(/^https:\/\/(www|a|b)\d?\.dailymail(int)?\.co\.uk/i)) {
        return;
      }
    } else if (!ENABLED_CHANNELS.includes(channel) && !ENABLED_SUBCHANNELS.includes(subchannel)) {
      return;
    }

    const [articles] = await Promise.all([
      getArticles(),
      later('DOM_READY')
    ]);

    if (!articles || !articles.length) {
      return;
    }

    placeArticlesInPuffs(articles);
  } catch (error) {
    logger.error('Error initialising mol-fe-auto-puff', error);
  }
};
