const customEventConstructor = () => {
  if (typeof window.CustomEvent === 'function') {
    return false;
  }

  // eslint-disable-next-line func-style
  function CustomEvent (event, paramsArg) {
    const params = paramsArg || {
      bubbles: false,
      cancelable: false,
      detail: undefined
    };
    const evt = document.createEvent('CustomEvent');

    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);

    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;

  return CustomEvent;
};

export default customEventConstructor;
