import {pageEvents} from '@mol-fe/mol-fe-tracking';
import {getPageMetadata} from '@mol-fe/mol-fe-page-metadata';
import {getArticleBody, getTruncationPoint} from './article';
import {TRUNCATION_HEIGHT_DESKTOP, TRUNCATION_HEIGHT_MOBILE} from './constants';
import {setToStorage} from './storage';

const viewableTrackingObserver = new IntersectionObserver((entries, obs) => {
  for (const entry of entries) {
    if (entry.isIntersecting) {
      const element = entry.target;

      pageEvents.publish(pageEvents.ARTICLE_TRUNCATION_VIEWABLE);

      obs.unobserve(element);
    }
  }
}, {threshold: 0.5});

let hasExpandedManually = false;

export const expandArticle = ({nonInteraction = false} = {}) => {
  const articleBody = getArticleBody();

  if (!articleBody || !articleBody.classList.contains('article-truncated')) {
    return;
  }

  articleBody.style.maxHeight = '';
  articleBody.classList.remove('article-truncated');

  const truncationBox = document.querySelector('.article-truncation-box');

  if (truncationBox) {
    viewableTrackingObserver.unobserve(truncationBox);
    truncationBox.remove();
  }

  pageEvents.publish(pageEvents.ARTICLE_TRUNCATION_EXPAND);

  if (!nonInteraction) {
    hasExpandedManually = true;
  }
};

export const truncateArticle = ({nonInteraction = false} = {}) => {
  const articleBody = getArticleBody();

  if (!articleBody) {
    return;
  }

  if (hasExpandedManually && nonInteraction) {
    return;
  }

  const isRetruncation = articleBody.classList.contains('article-truncated');
  const {renderPlatform} = getPageMetadata();
  const defaultTruncationHeight = renderPlatform === 'mobile' ? TRUNCATION_HEIGHT_MOBILE : TRUNCATION_HEIGHT_DESKTOP;
  const truncationHeight = getTruncationPoint() || defaultTruncationHeight;

  articleBody.style.maxHeight = `${truncationHeight}px`;
  articleBody.classList.add('article-truncated');

  let truncationBox = document.querySelector('.article-truncation-box');

  if (!truncationBox) {
    truncationBox = document.createElement('div');
    truncationBox.classList.add('article-truncation-box');
    truncationBox.innerHTML = `<div class="article-truncation-box__button-wrap">
      <button class="article-truncation-box__button" data-article-truncation-expand-button>CONTINUE READING...</button>
    </div>`;

    const button = truncationBox.querySelector('[data-article-truncation-expand-button]');

    button.addEventListener('click', () => expandArticle());
    articleBody.appendChild(truncationBox);
    viewableTrackingObserver.observe(truncationBox);
  }

  if (!isRetruncation) {
    setToStorage('last', Date.now());
    pageEvents.publish(pageEvents.ARTICLE_TRUNCATION_TRUNCATE);
  }
};
