const getAllStylesInPage = () => {
  const allStylesInPage = {};

  document.querySelectorAll('link')
    .forEach(({href}) => {
      if (href !== '') {
        allStylesInPage[href] = true;
      }
    });

  return allStylesInPage;
};

export default getAllStylesInPage;
