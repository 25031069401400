import jsCookie from 'js-cookie';

export const getMobileHomeTab = () => {
  try {
    return jsCookie.get('mol-home');
  } catch (error) {
    // ignore
  }

  return null;
};
