/* eslint-disable id-match */
import {getPageMetadata} from '@mol-fe/mol-fe-page-metadata';
import {setConfig} from '@mol-fe/mol-fe-components';
import {logger} from '@mol-fe/mol-fe-client-logger';
import {loadScript} from '@mol-fe/mol-fe-dom-helpers';
import {CUSTOM_DIMENSION_MAP, UA_ID, GTAG_SCRIPT_URL, GA4_ID} from './constants';
import {getConfigProps} from './meta/index';
import {toLowerCase} from './meta/utils';

// This needs to be like this
export const gtag = function () {
  // eslint-disable-next-line prefer-rest-params
  window.molFeDataLayer.push(arguments);
};

export const loadGtag = async () => {
  window.molFeDataLayer = window.molFeDataLayer || [];
  window.gtag = gtag;
  gtag('js', new Date());

  try {
    await loadScript(GTAG_SCRIPT_URL);
  } catch (error) {
    logger.error('Error loading gtag JS', error);
  }
};

let initialisedConfig = false;

const setConfigIfNeeded = () => {
  if (initialisedConfig) {
    return;
  }

  const props = toLowerCase(getConfigProps());
  const {
    environment
  } = getPageMetadata();
  const linkedDomains = environment === 'production' ?
    ['dailymail.co.uk', 'thisismoney.co.uk'] :
    ['dailymailint.co.uk', 'thisismoneyint.co.uk'];

  gtag('config', UA_ID, {
    custom_map: CUSTOM_DIMENSION_MAP,
    linker: {
      accept_incoming: true,
      domains: linkedDomains
    },
    send_page_view: false,
    ...props
  });

  gtag('config', GA4_ID, {
    linker: {
      accept_incoming: true,
      domains: linkedDomains
    },
    send_page_view: false,
    ...props
  });

  setConfig('molFeGAProps', props);
  initialisedConfig = true;
};

export const pageView = (props) => {
  setConfigIfNeeded();
  gtag('event', 'page_view', {
    ...toLowerCase(props),
    send_to: [UA_ID, GA4_ID]
  });
};

const MAX_EVENT_NAME_LENGTH = 40;

export const event = (action, props, ga4EventName) => {
  setConfigIfNeeded();
  gtag('event', action, {
    ...toLowerCase(props),
    send_to: UA_ID
  });

  let extendedEventName = ga4EventName;

  if (!extendedEventName) {
    const eventNamePrefix = props && props.event_category || 'unknown';
    const generatedEventName = `${eventNamePrefix}${action}`.replace(/\s/g, '');

    extendedEventName = `e_${generatedEventName}`;

    if (extendedEventName.length > MAX_EVENT_NAME_LENGTH) {
      extendedEventName = `e_trunc_${generatedEventName}`.substring(0, MAX_EVENT_NAME_LENGTH);
    }
  }

  if (extendedEventName) {
    gtag('event', extendedEventName, {
      ...toLowerCase(props),
      send_to: [GA4_ID]
    });
  }
};
