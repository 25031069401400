import {event} from '../gtag';

const createHandler = (category, action, nonInteraction) => (_, data) => {
  event(action, {
    // eslint-disable-next-line id-match
    event_category: category,
    // eslint-disable-next-line id-match
    event_label: data.type,
    // eslint-disable-next-line id-match
    non_interaction: Boolean(nonInteraction),
    typeofsmartbanner: data.type
  });
};

export const smartBannerHandlers = {
  SMART_BANNER_CLICKED: createHandler('smart banner', 'click'),
  SMART_BANNER_SHOWN: createHandler('smart banner', 'impression', true)
};
