import after from './ChildNode/after';
import before from './ChildNode/before';
import remove from './ChildNode/remove';
import replaceWith from './ChildNode/replaceWith';
import closest from './Element/closest';
import matches from './Element/matches';
import append from './ParentNode/append';
import prepend from './ParentNode/prepend';
import forEach from './NodeList/forEach';
import customEventConstructor from './customEventConstructor';

after();
before();
remove();
replaceWith();
closest();
matches();
append();
prepend();
forEach();
customEventConstructor();
