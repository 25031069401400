import {getPageMetadata, getPageCriteria} from '@mol-fe/mol-fe-page-metadata';
import {logger} from '@mol-fe/mol-fe-client-logger';
import {later} from '@mol-fe/mol-fe-async';
import {pageEvents} from '@mol-fe/mol-fe-tracking';
import {renderPullNotification} from './pullNotification';
import getHulk from './getHulk';
import getOptions from './getOptions';
import {scrollThreshold} from './scrollThreshold';

const loadRegister = async () => {
  try {
    const {webPush, articleId, renderPlatform, contentType} = getPageMetadata();
    const {channel, subchannel} = getPageCriteria();
    const {hostname, promptEnabled, pullNotificationEnabled, pullNotificationTTL = 5 * 60 * 1000, pullNotificationScrollThreshold} = webPush || {};
    const {Register} = await getHulk();
    const options = await getOptions();
    const isEnabled = hostname && location.hostname.includes(hostname);

    if (isEnabled) {
      const instance = new Register(options);

      instance.on('error', logger.error);

      if (Register.isWebPushSupported()) {
        instance.on('notification_click', (event) =>
          pageEvents.publish(pageEvents.WEB_PUSH_NOTIFICATION_CLICK, event)
        );
        instance.on('prompt_impression', (event) =>
          pageEvents.publish(pageEvents.WEB_PUSH_PROMPT_IMPRESSION, event)
        );
        instance.on('prompt_click', (event) =>
          pageEvents.publish(pageEvents.WEB_PUSH_PROMPT_CLICK, event)
        );

        await instance.init();

        if (promptEnabled) {
          await instance.showPrompt();
        }
      }

      if (pullNotificationEnabled && (contentType !== 'home' || channel !== subchannel || !['home', 'ushome', 'auhome'].includes(channel))) {
        instance.on('pull_notification_click', (event) =>
          pageEvents.publish(pageEvents.WEB_PULL_NOTIFICATION_CLICK, event)
        );

        await later('DOM_READY');

        if (typeof pullNotificationScrollThreshold === 'number' && pullNotificationScrollThreshold > 0) {
          const contentElement = renderPlatform === 'mobile' ? document.querySelector('.scrollable-content') : document;

          await scrollThreshold(pullNotificationScrollThreshold, contentElement);
        }

        await instance.initPullNotifications({
          canRender: (data) => typeof data === 'object' && (!data.assetId || data.assetId !== articleId),
          renderPullNotification: renderPullNotification.bind(null, pullNotificationTTL)
        });
      }
    }
  } catch (error) {
    logger.error(`Error initializing web push: ${error}`);
  }
};

export default loadRegister;
