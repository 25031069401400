export const getHostname = (href) => {
  try {
    if (typeof href === 'string') {
      const hrefUrl = new URL(href);

      return hrefUrl.hostname;
    }
  } catch (error) {
    // Ignore
  }

  return undefined;
};
