import {event} from '../gtag';

const createHandler = (category, action, nonInteraction) => (_, data) => {
  const {promotion, urlClicked, articlePosition} = data;

  event(action, {
    articlePosition,
    // eslint-disable-next-line id-match
    event_category: category,
    // eslint-disable-next-line id-match
    event_label: promotion,
    // eslint-disable-next-line id-match
    non_interaction: Boolean(nonInteraction),
    typeofpromotionsmodule: promotion,
    urlClicked
  });
};

export const promotionsModuleHandlers = {
  PROMOTIONS_XP_CLICK: createHandler('promotions module', 'click'),
  PROMOTIONS_XP_VIEW_MORE: createHandler('promotions module', 'view more')
};
