import {later} from '@mol-fe/mol-fe-async';
import {logger} from '@mol-fe/mol-fe-client-logger';
import {init} from './init';
import {reorderArticles} from './reorder';
import {addArticleRead, addArticleSeen} from './articles';

try {
  later('DOM_READY', init);
} catch (error) {
  logger.error('Error initialising mol-fe-reorder-mobile-channel', error);
}

export {reorderArticles, addArticleRead, addArticleSeen};
