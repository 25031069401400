import {event} from '../gtag';

const createHandler = (category, action, nonInteraction) => (_, data) => {
  const params = {
    // eslint-disable-next-line id-match
    event_category: category,
    // eslint-disable-next-line id-match
    non_interaction: Boolean(nonInteraction)
  };

  if (data && typeof data.value !== 'undefined') {
    params.value = data.value;

    if (action === 'load time') {
      params.loadTime = data.value;
    }
  }

  if (data && data.label) {
    // eslint-disable-next-line id-match
    params.event_label = data.label;
    params.selectedValue = data.label;
  }

  event(action, params);
};

export const openwebHandlers = {
  OPENWEB_DELETE_MESSAGE: createHandler('openweb', 'delete message'),
  OPENWEB_DOWNVOTE: createHandler('openweb', 'downvote'),
  OPENWEB_EDIT_MESSAGE: createHandler('openweb', 'edit message'),
  OPENWEB_IMPRESSION: createHandler('openweb', 'impression', true),
  OPENWEB_LOAD_ERROR: createHandler('openweb', 'load error', true),
  OPENWEB_LOAD_TIME: createHandler('openweb', 'load time', true),
  OPENWEB_LOGIN_START: createHandler('openweb', 'login start'),
  OPENWEB_OPEN_PROFILE: createHandler('openweb', 'open profile'),
  OPENWEB_POST_LOGIN_SUCCESS: createHandler('openweb', 'post login success'),
  OPENWEB_SEND_FAIL: createHandler('openweb', 'send fail'),
  OPENWEB_SEND_SUCCESS: createHandler('openweb', 'send success'),
  OPENWEB_SHARE_CONTEXT: createHandler('openweb', 'share context'),
  OPENWEB_SHARE_TYPE: createHandler('openweb', 'share type'),
  OPENWEB_SORT_OPEN: createHandler('openweb', 'sort open'),
  OPENWEB_SORT_SELECT: createHandler('openweb', 'sort select'),
  OPENWEB_UPVOTE: createHandler('openweb', 'upvote'),
  OPENWEB_VIEWABLE: createHandler('openweb', 'viewable', true)
};
