const createDocumentFragment = function (elements) {
  const documentFragment = document.createDocumentFragment();

  elements.forEach((element) => {
    const isNode = element instanceof Node;
    const child = isNode ? element : document.createTextNode(String(element));

    documentFragment.appendChild(child);
  });

  return documentFragment;
};

export default createDocumentFragment;
