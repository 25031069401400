import {PACKAGE_NAME} from '../../../../../config';

export const FILENAME = 'removeXpModuleScriptsFromHead';

const removeXpModuleScriptsFromHead = (scripts) => {
  if (!Array.isArray(scripts)) {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.invalidProps`, {
        scripts,
      }
    );

    return null;
  }

  try {
    for (const scriptUrl of scripts) {
      const script = document.querySelector(`head script[src="${scriptUrl}"]`);

      if (script) {
        script.remove();
      }
    }

    return true;
  } catch (error) {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.thrownError`,
      {
        error,
        scripts,
      }
    );

    return null;
  }
};

export default removeXpModuleScriptsFromHead;
