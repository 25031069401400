/* eslint-disable complexity */
import {getPageMetadata} from '@mol-fe/mol-fe-page-metadata';
import {later} from '@mol-fe/mol-fe-async';
import URL from 'url-parse';
import {getFromStorage, setToStorage} from './storage';
import {addMobileMenuButton, addDesktopMastheadButton, getDestinationUrl} from './mount';

const MOL_DOMAIN_REGEX = /dailymail(int)?\.(co\.uk|com)/;
const MAX_SESSION_LIFETIME = 30 * 60 * 1000;

const setAdvertsIco = () => {
  if (!window.adverts) {
    window.adverts = {};
  }

  if (!window.adverts.queue) {
    window.adverts.queue = [];
  }

  window.adverts.queue.push({
    data: {
      referrer: 'dailymail'
    },
    scope: 'metadata'
  });
};

const addToPage = async (origin) => {
  const {renderPlatform} = getPageMetadata();
  const relevantElement = document.querySelector('.page-header .masthead, .tim-mobile header');

  window.timCameFromMolUrl = getDestinationUrl(origin);
  document.documentElement.classList.add('came-from-mol');
  setAdvertsIco();

  if (!relevantElement) {
    await later('DOM_READY');
  }

  if (renderPlatform === 'mobile') {
    addMobileMenuButton(origin);
  } else if (renderPlatform === 'desktop') {
    addDesktopMastheadButton(origin);
  }
};

const addUrlParamToMolLinks = () => {
  const links = Array.from(document.querySelectorAll('a[href*="www.thisismoney"][href*="ico=mol_"]:not([data-mutated-tim-url-param]), a[href*="www.thisismoney"][href*="ico=topics_pagination_"]:not([data-mutated-tim-url-param]), a[href*="www.thisismoney"][href*="ico=authors_pagination_"]:not([data-mutated-tim-url-param])'));

  for (const link of links) {
    const linkUrl = link.getAttribute('href');
    const parsedLink = new URL(linkUrl, true);
    const query = parsedLink.query;
    const newIco = `${query.ico}-newtab`;

    query.ico = newIco;
    query.molReferrerUrl = location.href;
    parsedLink.set('query', query);

    link.setAttribute('href', parsedLink.href);
    link.dataset.mutatedTimUrlParam = true;
    link.target = '_blank';

    const commentsLink = link.parentNode.querySelector('.comments-count > a:not([data-mutated-tim-url-param])');

    if (commentsLink) {
      const hash = commentsLink.getAttribute('href').split('#')[1];

      commentsLink.setAttribute('href', `${parsedLink.href}#${hash}`);
      commentsLink.dataset.mutatedTimUrlParam = true;
      commentsLink.target = '_blank';
    }
  }

  setTimeout(addUrlParamToMolLinks, 1000);
};

export const init = () => {
  const {domain} = getPageMetadata();

  if (domain !== 'thisismoney') {
    addUrlParamToMolLinks();

    return;
  }

  const session = getFromStorage('session');
  const {updated, referrers, origin} = session || {};
  const referrerInSession = referrers && document.referrer && referrers.includes(document.referrer);
  const pageInSession = referrers && referrers.includes(location.href);
  const referrerIsOrigin = origin && document.referrer && document.referrer === origin;
  const isCurrentSession = (referrerInSession || pageInSession || referrerIsOrigin) && updated && Date.now() - updated < MAX_SESSION_LIFETIME;

  if (isCurrentSession) {
    addToPage(origin);

    const newReferrers = pageInSession ? referrers : [...referrers, location.href].slice(-50);

    setToStorage('session', {
      ...session,
      referrers: newReferrers,
      updated: Date.now()
    });

    return;
  }

  const parsedCurrentUrl = new URL(window.location.href, true);
  const referrerFromUrl = parsedCurrentUrl && parsedCurrentUrl.query && parsedCurrentUrl.query.molReferrerUrl;
  const referrer = referrerFromUrl || document.referrer;
  const parsedReferrer = referrer ? new URL(referrer, true) : null;
  const ico = parsedCurrentUrl && parsedCurrentUrl.query ? parsedCurrentUrl.query.ico : null;
  const cameFromMolOnThisPage = parsedReferrer && parsedReferrer.hostname.match(MOL_DOMAIN_REGEX) && ico && (ico.startsWith('mol_') || ico.startsWith('topics_pagination_') || ico.startsWith('authors_pagination_'));

  if (cameFromMolOnThisPage) {
    setToStorage('session', {
      origin: referrer,
      referrers: [location.href],
      updated: Date.now()
    });
    addToPage(referrer);
  }
};
