// https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/after
import createDocumentFragment from '../utils/createDocumentFragment';

const after = () => {
  const prototypes = [Element.prototype, CharacterData.prototype, DocumentType.prototype];

  prototypes.forEach((prototype) => {
    if (typeof prototype.after !== 'function') {
      prototype.after = function (...elements) {
        const documentFragment = createDocumentFragment(elements);

        this.parentNode.insertBefore(documentFragment, this.nextSibling);
      };
    }
  });
};

export default after;
