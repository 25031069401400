import {later} from '@mol-fe/mol-fe-async';

let pageMetadata = {};

const getPageMetadata = function (overrides) {
  return Object.assign({}, pageMetadata, overrides);
};

const setPageMetadata = function (newPageMetadata) {
  pageMetadata = newPageMetadata;
  later.go('PAGE_METADATA_SET');
};

const getPageCriteria = function () {
  return Object.assign({}, window.PageCriteria);
};

export {
  getPageCriteria,
  getPageMetadata,
  setPageMetadata
};
