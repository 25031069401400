import {getAutoRefresh} from '../../utils/getAutoRefresh';
import {isReload} from './isReload';

export const getAutoRefreshStatus = () => {
  const cookieValue = getAutoRefresh();

  if (cookieValue) {
    return `autorefresh-${cookieValue}`;
  }

  if (isReload()) {
    return 'page-reloaded';
  }

  return undefined;
};
