import {PACKAGE_NAME} from '../../../../../../config';

export const FILENAME = 'disableXpModuleNode';

const disableXpModuleNode = (xpModuleNode) => {
  if (!(xpModuleNode instanceof HTMLDivElement)) {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.invalidProps`,
      {
        xpModuleNode,
      }
    );

    return null;
  }

  xpModuleNode.style.opacity = '0.5';
  xpModuleNode.style.pointerEvents = 'none';
  xpModuleNode.style.cursor = 'default';

  return xpModuleNode;
};

export default disableXpModuleNode;
