import axios from 'axios';
import {hostname, PACKAGE_NAME, TIMEOUT_IN_MILLISECONDS} from '../../../../config';

export const FILENAME = 'retrieveXpModuleBody';

const retrieveXpModuleBody = async ({
  name,
  env,
  values
}) => {
  let url;

  try {
    url = `https://${hostname}/xpmodules/${name}/renderBody?data=${encodeURIComponent(JSON.stringify({env,
      values}))}`;
  } catch (error) {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.urlError`,
      {
        env,
        error,
        hostname,
        name,
        values
      }
    );

    return null;
  }

  try {
    const {
      data: body
    } = await axios.get(url, {
      timeout: TIMEOUT_IN_MILLISECONDS
    });

    if (typeof body !== 'string' || body === '') {
      console.error(
        `[${PACKAGE_NAME}] ${FILENAME}.invalidBody`,
        {
          body,
          url,
        }
      );

      return null;
    }

    return body;
  } catch (error) {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.thrownError`,
      {
        error,
        url
      }
    );

    return null;
  }
};

export default retrieveXpModuleBody;
