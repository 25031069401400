import {PACKAGE_NAME} from '../../../../../../config';

export const FILENAME = 'createLinkFromUrl';

const createLinkFromUrl = (url, onLoad, onError) => {
  if (typeof url !== 'string') {
    console.error(`[${PACKAGE_NAME}] ${FILENAME}.invalidProps`, {
      url,
    });

    return null;
  }

  if (typeof onLoad !== 'function') {
    console.error(`[${PACKAGE_NAME}] ${FILENAME}.invalidProps`, {
      onLoad,
    });

    return null;
  }

  if (typeof onError !== 'function') {
    console.error(`[${PACKAGE_NAME}] ${FILENAME}.invalidProps`, {
      onError,
    });

    return null;
  }

  const link = document.createElement('link');

  link.type = 'text/css';
  link.rel = 'stylesheet';
  link.onload = onLoad;
  link.onerror = onError;
  link.href = url;

  return link;
};

export default createLinkFromUrl;
