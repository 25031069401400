import {TIMEOUT_IN_MILLISECONDS, PACKAGE_NAME} from '../../../../../config';
import createLinkFromUrl from './utils/createLinkFromUrl';

export const FILENAME = 'addXpModuleStylesToHead';

// tries to add the xpModule styles to the head of the document
// returns a promise that resolves when the styles are added to the head or when there is an error
// if the styles are added to the head, the promise resolves the state of every style
// if there are any error, the promise resolves null
const addXpModuleStylesToHead = (styles) => new Promise((resolve) => {
  const state = {
    added: [],
    errored: [],
    loaded: [],
  };

  if (!Array.isArray(styles)) {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.invalidProps`, {
        state,
        styles,
      }
    );

    return resolve(null);
  }

  if (styles.length === 0) {
    return resolve(state);
  }

  let isResolved = false;

  const timeout = setTimeout(() => {
    if (isResolved) {
      return undefined;
    }

    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.timeout`, {
        state,
        styles,
        TIMEOUT_IN_MILLISECONDS,
      }
    );

    isResolved = true;

    return resolve(null);
  }, TIMEOUT_IN_MILLISECONDS);

  const onLoad = (event) => {
    state.loaded.push(event.target.href);

    if (state.loaded.length === styles.length) {
      clearTimeout(timeout);
      isResolved = true;

      return resolve(state);
    }

    return undefined;
  };

  const onError = (event) => {
    if (isResolved) {
      return undefined;
    }

    state.errored.push(event.target.href);

    console.error(`[${PACKAGE_NAME}] ${FILENAME}.onError`, {
      erroredScript: event.target.src,
      state,
      styles,
    });

    clearTimeout(timeout);
    isResolved = true;

    return resolve(null);
  };

  for (const url of styles) {
    const link = createLinkFromUrl(url, onLoad, onError);

    if (!(link instanceof HTMLLinkElement)) {
      console.error(
        `[${PACKAGE_NAME}] ${FILENAME}.createLinkFromUrl`, {
          link,
          onError,
          onLoad,
          state,
          url,
        }
      );

      clearTimeout(timeout);
      isResolved = true;

      return resolve(null);
    }

    document.head.appendChild(link);

    state.added.push(url);
  }

  return undefined;
});

export default addXpModuleStylesToHead;
