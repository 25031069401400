import {PACKAGE_NAME} from '../../../../../config';

export const FILENAME = 'removeXpModuleNode';

const removeXpModuleNode = (node) => {
  if (!(node instanceof HTMLDivElement)) {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.invalidProps`,
      {
        node,
      }
    );

    return null;
  }

  try {
    node.remove();

    return true;
  } catch (error) {
    console.error(
      `[${PACKAGE_NAME}] ${FILENAME}.thrownError`,
      {
        error,
        node,
      }
    );

    return null;
  }
};

export default removeXpModuleNode;
