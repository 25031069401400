import {getPageMetadata} from '@mol-fe/mol-fe-page-metadata';
import {event} from '../gtag';

const handlePageRender = () => {
  const {betwayBannerPresent} = getPageMetadata();

  if (betwayBannerPresent) {
    event('display betway breaking news', {
      // eslint-disable-next-line id-match
      event_category: 'banner',
      // eslint-disable-next-line id-match
      non_interaction: true
    });
  }
};

export const betwayBannerHandlers = {
  PAGE_RENDER_STARTED: handlePageRender
};
