import {event} from '../gtag';

const createHandler = (category, action, nonInteraction) => (_, data) => {
  const {campaignId} = data;

  event(action, {
    campaignId,
    // eslint-disable-next-line id-match
    event_category: category,
    // eslint-disable-next-line id-match
    event_label: campaignId,
    // eslint-disable-next-line id-match
    non_interaction: Boolean(nonInteraction)
  });
};

export const guestbookHandlers = {
  GUESTBOOK_IMPRESSION: createHandler('guestbook', 'impression', true),
  GUESTBOOK_LOADING_ERROR: createHandler('guestbook', 'loading error', true),
  GUESTBOOK_SUBMIT_ERROR: createHandler('guestbook', 'submit error'),
  GUESTBOOK_SUBMIT_SUCCESS: createHandler('guestbook', 'submit success')
};
