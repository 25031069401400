export const isReload = () => {
  try {
    if ('PerformanceNavigationTiming' in window) {
      const entry = performance.getEntriesByType('navigation')[0];

      return entry.type === 'reload';
    }

    if ('PerformanceNavigation' in window) {
      return performance.navigation.type === performance.navigation.TYPE_RELOAD;
    }
  } catch (error) {
    // ignore
  }

  return false;
};
