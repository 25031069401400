import {isArticleExpired, isArticleInRead, isArticleInSeen} from './articles';

const MAX_SWAPS = 30;
const SKIP_FIRST_N = 2;

const swapPreviews = (firstPreview, secondPreview) => {
  const beforeFirstPreview = firstPreview.previousElementSibling;

  if (!beforeFirstPreview) {
    return;
  }

  secondPreview.after(firstPreview);
  beforeFirstPreview.after(secondPreview);
};

export const reorderArticles = () => {
  const articlePreviews = Array.from(document.querySelectorAll('.article-preview[data-id]')).slice(SKIP_FIRST_N);
  const previewsToSwap = articlePreviews.slice(0, MAX_SWAPS).filter((preview) => {
    const articleId = Number(preview.dataset.id);

    return isArticleExpired(articleId);
  });

  if (!previewsToSwap.length) {
    return;
  }

  const lastSwapPreview = previewsToSwap[previewsToSwap.length - 1];
  const lastSwapChildIndex = Array.from(lastSwapPreview.parentNode.children).indexOf(lastSwapPreview);

  const freshPreviews = articlePreviews.filter((preview) => {
    const childIndex = Array.from(preview.parentNode.children).indexOf(preview);

    if (childIndex <= lastSwapChildIndex) {
      return false;
    }

    const articleId = Number(preview.dataset.id);

    return !isArticleInRead(articleId) && !isArticleInSeen(articleId);
  });
  const swappablePreviews = previewsToSwap.slice(0, freshPreviews.length);

  let swappedIndex = 0;

  for (const previewToSwap of swappablePreviews) {
    if (swappedIndex >= freshPreviews.length) {
      return;
    }

    const freshPreview = freshPreviews[swappedIndex];

    swapPreviews(previewToSwap, freshPreview);

    swappedIndex++;
  }
};
