/* eslint-disable id-match, consistent-return */
import {deviceData} from '@mol-fe/mol-fe-mobileutils';

const isWin10Compatible = function () {
  const win10Regex = /(Windows *[a-zA-Z]*) ([^; )]+)/i;
  const win10Match = win10Regex.exec(navigator.userAgent);

  return win10Match && win10Match.length > 1 && win10Match[1].toLowerCase() === 'windows nt' && parseFloat(win10Match[2]) >= 6.4;
};

const isDesktop = function () {
  return document.body.classList.contains('mol-desktop');
};

const getPlatformOpts = function (opts) {
  if (deviceData.isWindows && opts.windowsPhone) {
    return Object.assign({platform: 'windowsPhone'}, opts.windowsPhone);
  }
  if (deviceData.isIDevice && opts.ios) {
    return Object.assign({platform: 'ios'}, opts.ios);
  }
  if (deviceData.isAndroid && opts.android) {
    return Object.assign({platform: 'android'}, opts.android);
  }
  if (isWin10Compatible() && opts.windows10) {
    return Object.assign({platform: 'windows10'}, opts.windows10);
  }
  if (isDesktop() && opts.desktop) {
    return Object.assign({platform: 'desktop'}, opts.desktop);
  }
};

export {
  getPlatformOpts,
  isDesktop,
  isWin10Compatible
};
