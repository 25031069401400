import {event} from '../gtag';

const numberOrZero = function (value) {
  return parseFloat(value) || 0;
};

// eslint-disable-next-line arrow-body-style
const getArticleIdOrHub = (data) => {
  return data && data.articleId ? data.articleId : 'fff-hub';
};

// eslint-disable-next-line complexity
const getFFFDimensions = (data, action) => {
  const articleId = getArticleIdOrHub(data.pageMetaData);

  return {
    fffActions: [
      'FFF',
      action,
      data.fffSource || '',
      numberOrZero(data.position),
      data.celebrity || '',
      data.mainProduct || '',
      data.relatedProduct || data.accessoryProduct || '',
      data.items || '',
      data.trends || '',
      data.affiliate || '',
      data.depth || ''
    ].join('^'),
    fffLayout: [
      data.fffType || '',
      data.fffSource || '',
      articleId || ''].join('^')
  };
};

const createHandler = (category, action) => (_, data) => {
  event(action, {
    // eslint-disable-next-line id-match
    event_category: category,
    ...getFFFDimensions(data, action)
  });
};

const handleSocialClick = (_, data, nonInteraction) => {
  const action = 'social share';

  event(action, {
    // eslint-disable-next-line id-match
    event_category: 'FFF',
    // eslint-disable-next-line id-match
    event_label: data.socialSite,
    // eslint-disable-next-line id-match
    non_interaction: Boolean(nonInteraction),
    socialNetwork: data.socialSite,
    ...getFFFDimensions(data, action)
  });
};

export const fffHandlers = {
  FFF_BUY_ACCESSORY_CLICKED: createHandler('fff', 'accessory product buynow click'),
  FFF_BUY_MAIN_CLICKED: createHandler('fff', 'main product buynow click'),
  FFF_BUY_RELATED_CLICKED: createHandler('fff', 'alternative product buynow click'),
  FFF_BUY_TRENDING_CLICKED: createHandler('fff', 'trending product buynow click'),
  FFF_CAROUSEL_CLICKED: createHandler('fff', 'carousel use'),
  FFF_CONTENT_VIEWED: createHandler('fff', 'product view', true),
  FFF_HUB_SEARCHED: createHandler('fff', 'search'),
  FFF_LINK_TO_AFFILIATE_CLICKED: createHandler('fff', 'affiliate link click'),
  FFF_LINK_TO_ARTICLE_CLICKED: createHandler('fff', 'click to article'),
  FFF_LINK_TO_HUB_CLICKED: createHandler('fff', 'click to hubpage'),
  FFF_SOCIAL_CLICKED: handleSocialClick
};
