import {getConfig} from '@mol-fe/mol-fe-components';
import {later} from '@mol-fe/mol-fe-async';
import {logger} from '@mol-fe/mol-fe-client-logger';
import infiniteScroll from './infiniteScroll';

Promise.all([
  getConfig('molFeInfiniteScroll'),
  later('DOM_READY')
]).then(([config = {}]) => {
  const {
    selector,
    scrollCheckSelector,
    fetchUrl
  } = config;

  const element = selector && document.body.querySelector(selector) || document.body.querySelector('.infinite-scroll');
  const scrollCheckElement = scrollCheckSelector && document.body.querySelector(scrollCheckSelector) || document.body.querySelector('.scrollable-content');

  if (!fetchUrl) {
    // eslint-disable-next-line no-console
    console.warning('molFeInfiniteScroll : fetchUrl is required');
  } else if (!scrollCheckElement || !element) {
    // eslint-disable-next-line no-console
    console.warning(`molFeInfiniteScroll : can't find ${scrollCheckElement} or ${element}`);
  } else {
    return infiniteScroll({
      element,
      fetchUrl,
      scrollCheckElement
    });
  }

  return false;
}).catch((error) => logger.error(error.message));
