import {later} from '@mol-fe/mol-fe-async';
import {logger} from '@mol-fe/mol-fe-client-logger';
import {deviceData, isLandscape} from '@mol-fe/mol-fe-mobileutils';
import {moduleTracking, pageEvents} from '@mol-fe/mol-fe-tracking';
import {setCookieAndReinforce} from '@mol-fe/mol-fe-cookie-reinforcer';
import cookie from 'js-cookie';
import debounce from 'lodash/debounce';
import styles from './smartBanner.scss';
import {isDesktop, isWin10Compatible} from './utils/platform';

const APP_IDS = {
  MAIL_ONLINE: 'mol',
  MAIL_ONLINE_FANTASY_FOOTBALL: 'fantasyfootball',
  THIS_IS_MONEY: 'tim'
};
const COOKIE_NAME = 'smart-banner';
const COOKIE_DURATION = 30;

const smartBanner = {
  inited: false
};

const getLogoCssClass = (appId) => {
  let cssClass;

  switch (appId) {
  case APP_IDS.MAIL_ONLINE_FANTASY_FOOTBALL:
    cssClass = styles.molffLogo;
    break;
  case APP_IDS.THIS_IS_MONEY:
    cssClass = styles.timLogo;
    break;
  default:
    cssClass = styles.molLogo;
    break;
  }

  return cssClass;
};

const setBannerClosedCookie = function () {
  const expires = new Date(Date.now() + 1000 * 60 * 60 * 24 * COOKIE_DURATION);

  setCookieAndReinforce(COOKIE_NAME, 'closed', expires);
};

const ratingFactory = function (data) {
  if (data.averageUserRating >= 3) {
    const full = Math.floor(data.averageUserRating);
    const half = data.averageUserRating % 1 >= 0.25 ? 1 : 0;
    const empty = 5 - (full + half);
    const scores = [
      {
        class: styles.starFull,
        count: full
      },
      {
        class: styles.starHalf,
        count: half
      },
      {
        class: styles.starEmpty,
        count: empty
      }
    ];

    return scores.reduce((rating, score) => rating + `<span class="${score.class}"></span>`.repeat(score.count), '');
  } else {
    return '';
  }
};

const getBannerType = function () {
  try {
    return (isWin10Compatible() ? 'windows10' : deviceData.mobileName.toLowerCase()) + ' banner';
  } catch (error) {
    return 'unknown banner';
  }
};

const hide = function () {
  logger.debug('smartBanner: hiding');
  document.body.classList.remove('smart-banner');
};

const show = function () {
  logger.debug('smartBanner: showing');
  document.body.classList.add('smart-banner-shown');
  document.body.classList.add('smart-banner');
  pageEvents.publish(pageEvents.SMART_BANNER_SHOWN, {
    type: getBannerType()
  });
};

const toggleVisibility = function () {
  if ((cookie.get(COOKIE_NAME) || !isWin10Compatible() && isLandscape()) && document.body.classList.contains('smart-banner')) {
    hide();
  }

  if (!cookie.get(COOKIE_NAME) && (isWin10Compatible() || !isLandscape()) && !document.body.classList.contains('smart-banner')) {
    show();
  }
};
const debouncedToggle = debounce(toggleVisibility, 50);

const renderBanner = function (data) {
  if (!data) {
    return null;
  }

  if (window.location.search && window.location.search.match(/[?&]source=newzit\.app/)) {
    return null;
  }

  const bannerName = (isWin10Compatible() ? 'windows10' : deviceData.mobileName.toLowerCase()) + '_banner';
  const bannerHTML = `
<a data-google-interstitial="false" class="${styles.close}"></a>
<div class="${getLogoCssClass(data.app)}"></div>
<div class="${styles.info}">
  <div class="${styles.name}">${data.trackCensoredName}</div>
  <div class="${styles.ratings}">${ratingFactory(data)}</div>
  <div class="${styles.price}">${data.formattedPrice}</div>
</div>
<a href="${data.storeUrl}" rel="noskim" data-google-interstitial="false" class="${styles.view} js-tl">View</a>`;

  const banner = document.createElement('div');

  banner.setAttribute('id', 'smartBanner');
  banner.setAttribute('data-track-module', bannerName + '^smart-banner');
  banner.setAttribute('data-track-pos', 'static');
  banner.setAttribute('data-track-type', 'cl');
  banner.setAttribute('data-track-selector', `a.${styles.view}`);
  banner.classList.add(styles.smartBanner);
  banner.innerHTML = bannerHTML;

  document.body.appendChild(banner);
  moduleTracking.externalLinkTracker('smartBanner');

  smartBanner.banner = banner;
  smartBanner.bannerName = bannerName;
  smartBanner.inited = true;
  smartBanner.banner = banner;

  let interval;

  if (isDesktop()) {
    interval = setInterval(toggleVisibility, 1000);
  }

  banner.querySelector(`.${styles.close}`)
    .addEventListener('click', (event) => {
      event.preventDefault();
      setBannerClosedCookie();
      hide();
      if (interval) {
        clearInterval(interval);
        interval = null;
      }
    });

  banner.querySelector(`.${styles.view}`)
    .addEventListener('click', () => {
      pageEvents.publish(pageEvents.SMART_BANNER_CLICKED, {
        type: getBannerType()
      });
    });

  window.addEventListener('orientationchange', debouncedToggle);

  toggleVisibility();

  logger.info('###### SMARTBANNER RENDERED');

  return smartBanner.banner;
};

const init = function (opts) {
  logger.info('###### INIT SMARTBANNER', opts);

  if (!smartBanner.initPromise) {
    smartBanner.initPromise = later('BODY_READY')
      .then(() => renderBanner(opts));
  }

  return smartBanner.initPromise;
};

smartBanner.init = init;

export default smartBanner;
